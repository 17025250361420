<style>
.btn-gohome {
  width: 60%;
  margin: auto;
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  color: #fff;
  background-color: #ff5011;
}
body.default-style #illness-review .blue-bg {
  background-image: linear-gradient(0deg, #005187 0%, #00457b 100%);
  color: #fff;
  text-align: center;
  padding: 0;
}
body.default-style #illness-review .va-number {
  background-color: #003b71;
  color: #fff;
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}
.btn-copy {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  margin-right: auto;
  /* box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1); */
}
.btn-copy:hover {
  color: #fe632b;
}
.btn-waiting {
  background-color: transparent;
  border: 2px solid;
  border-color: #ccc;
  /* color: #003b71; */
  font-weight: 600;
  font-size: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-waiting.active {
  background-color: transparent;
  border: 2px solid;
  border-color: #ff5011;
  /* color: #003b71; */
  font-weight: 600;
  font-size: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (max-width: 470px) {
  .img-va{
  width: 70px;
}
}
@media (max-width: 365px) {
  .img-va{
    width: 50px;
  }
}
@media (max-width: 390px) {
  .nomor-va{
    font-size: 1.5em;
  }
}

.box-waiting {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-va {
  background-color: #FFECE5;
  border: 2px solid #FF4713;
  border-radius:  8px;
}

.info-icon {
  width: 30px;
  height: 30px;
}

.info-icon .fill-orange {
  fill: #FF4713;
}
</style>
<template>
  <div id="illness-review" style="min-height: 90vh">
    <div class="blue-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 text-center pt-3">
            <!-- <h3 class="text-center mb-5">{{$t("paymentConfirm")}}</h3> -->
            <img src="images/thankyou-va.png" alt="" style="height: 150px" />
            <div class="thank2you" v-if="Global.tp === 'nb'">
              <h5 class="text-center h5 py-3">
                {{ $t("policyStart") }} <br />
                {{ $t("policyStart1") }} <br />
                {{ $t("policyStart2") }}
              </h5>
            </div>

            <!-- <div class="text-center" style="margin: 3vh auto 0;" >
                            <button id="arrow2next" class="btn btn-outline" style="cursor:pointer;" v-if="false">
                                <h2 class="text-default animated2fast bounce2fast">&#8595;</h2>
                            </button>
                        </div> -->
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 0 15px; background-color: #fff;" class="mb-5">
      <div class="va-number my-4" v-for="(data, i) in Global.resultCreateVa" :key="i">
        <section
          style="
            background-image: url('images/circle-va.png');
            background-repeat: no-repeat;
            background-position: bottom right;
            min-height: 100px;
            padding: 20px;
          "
          v-for="(item, j) in data[Global.VAinfo.bank]" :key="j"
        >
          <p>{{ $t("AccountNumber") + " " }}{{ item.bank }}</p>
          <h3 class="d-flex nomor-va">
            <strong class="flex-grow-1">{{ item.accountNumber }}</strong>
            <button
              type="button"
              class="btn btn-copy"
              @click="
                copy(item.accountNumber);
                makeToast(item.bank + ' ' + item.accountNumber);
              "
            >
              <span class="glyphicons glyphicons-copy"></span> {{ $t("copy") }}
            </button>
          </h3>
          <p class="mt-5">{{ $t("amount") }}</p>
          <h3>
            {{ $t("idr") }} <strong> {{ formatNumber(item.price) }}</strong>
          </h3>
        </section>
      </div>

      <div class="alert alert-va d-flex align-items-stretch" style="max-width: 500px; margin-left: auto; margin-right: auto;">
        <div class="d-flex align-items-center">
          <svg class="info-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
          <path class="fill-orange" d="M13.5,7.5h3v3h-3V7.5z M13.5,13.5h3v9h-3V13.5z M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z
             M15,27C8.4,27,3,21.6,3,15S8.4,3,15,3s12,5.4,12,12S21.6,27,15,27z"/>
          </svg>
        </div>

        <div class="text-black pl-3">
          {{ $t("minimumVAPayment") }}
        </div>
      </div>
    </div>

      

    <h5 class="text-center my-3">{{ $t("howtoPayment") }}</h5>



    <div class="container" style="max-width:700px">
        <div class="row my-3">
          <div :class="
            'col-' +
            countVA +
            ' col-sm-' +
            countVA +
            ' col-md-' +
            countVA +
            ' col-lg-' +
            countVA + ' mt-2'
          " v-for="(data, i) in Global.responseCreateVa" :key="i"
          >
            <a style="display: block; margin-left: auto; margin-right: auto;"
              class="btn btn-waiting"
              data-toggle="collapse"
              v-bind:class="{'active' : Global.VAinfo.bank == data.bank }"
              :href="'#accordion-' + data.bank"
              role="button"
              aria-expanded="false"
              aria-controls="multiCollapseExample1"
              @click="
                changeBank(data.bank, data.accountNumber)
              "
            >
              <img
                  class="img-va" 
                  :src="'images/' + data.bank + '-logo.svg'"
                  :alt="'Bank' + data.bank"
                />
            </a>

            <!-- if -->
          </div>
        </div>
        <!-- <div class="accordion row box-waiting" id="accordion-ebanking"> -->
          <div class="row" v-for="(data, i) in Global.VirtualAccount" :key="i">
            <div
              class="accordion col-12 col-sm-12 col-md-12 col-lg-12"
              :id="'accordion-' + data.bank"
              v-if="Global.VAinfo.bank == data.bank"
            >
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style="text-decoration: none; color: #003b71"
                    >
                      Mobile Banking {{ data.bank }}
                      <span class="glyphicons glyphicons-chevron-down"></span>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva1'" v-if="$t('mbanking_' + data.bank + '.guideva1') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva1') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide1') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva1") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva1") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva2'" v-if="$t('mbanking_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva3'" v-if="$t('mbanking_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva4'" v-if="$t('mbanking_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva5'" v-if="$t('mbanking_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva5") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva6'" v-if="$t('mbanking_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva6") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva7'" v-if="$t('mbanking_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva8'" v-if="$t('mbanking_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      style="text-decoration: none; color: #003b71"
                    >
                      Internet Banking {{ data.bank }}
                      <span class="glyphicons glyphicons-chevron-down"></span>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva1'" v-if="$t('internetBanking_' + data.bank + '.guideva1') !== ''">
                        <a v-if="$t('internetBanking_' + data.bank + '.link') !== ''" :href="
                            data.bank == 'MANDIRI'
                              ? 'https://ibank.bankmandiri.co.id/'
                              : data.bank == 'BNI'
                              ? 'https://ibank.bni.co.id/' 
                              : data.bank == 'PERMATA' 
                              ? 'https://www.permatanet.com/' 
                              : data.bank == 'BRI'
                              ? 'https://ib.bri.co.id/ib-bri/'
                              : 'https://ibank.klikbca.com/'
                          "
                          target="_blank"
                          style="color: #fe632b">{{
                            data.bank == "MANDIRI"
                              ? "https://ibank.bankmandiri.co.id/"
                              : data.bank == "BNI"
                              ? "https://ibank.bni.co.id/"
                              : data.bank == 'PERMATA' 
                              ? 'https://www.permatanet.com/' 
                              : data.bank == 'BRI'
                              ? 'https://ib.bri.co.id/ib-bri/'
                              : "https://ibank.klikbca.com/"
                          }}</a>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva2'" v-if="$t('internetBanking_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva3'" v-if="$t('internetBanking_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva4'" v-if="$t('internetBanking_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva5'" v-if="$t('internetBanking_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.0.guideva5') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva5') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva5") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.1.guideva5') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva5') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva5") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva5') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.2.boldguide5') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.2.guideva5') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva5") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva5') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva5") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva6'" v-if="$t('internetBanking_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.0.guideva6') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva6') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva6") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.1.guideva6') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva6') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva6") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva6') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.2.boldguide6') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.2.guideva6') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva6") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva6') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva6") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva7'" v-if="$t('internetBanking_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva8'" v-if="$t('internetBanking_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva9'" v-if="$t('internetBanking_' + data.bank + '.guideva9') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva9') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.0.guideva9") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva9") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading4">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                      style="text-decoration: none; color: #003b71"
                    >
                      {{ $t("paymentATM") }} {{ data.bank }}
                      <span class="glyphicons glyphicons-chevron-down"></span>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapse4"
                  class="collapse"
                  aria-labelledby="heading4"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva1'" v-if="$t('atmbank_' + data.bank + '.guideva1') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva1') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide1') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva1") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva1") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva2'" v-if="$t('atmbank_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva3'" v-if="$t('atmbank_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva4'" v-if="$t('atmbank_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva5'" v-if="$t('atmbank_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva5") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva6'" v-if="$t('atmbank_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva6") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva7'" v-if="$t('atmbank_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva8'" v-if="$t('atmbank_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="card" v-if="data.bank == 'BRI'">
                <div class="card-header" id="heading5">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse5"
                      aria-expanded="false"
                      aria-controls="collapse5"
                      style="text-decoration: none; color: #003b71"
                    >
                      Mini ATM {{ data.bank }}
                      <span class="glyphicons glyphicons-chevron-down"></span>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapse5"
                  class="collapse"
                  aria-labelledby="heading5"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva1'" v-if="$t('miniatm_' + data.bank + '.guideva1') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva1') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide1') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva1") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva1") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva2'" v-if="$t('miniatm_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva3'" v-if="$t('miniatm_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva4'" v-if="$t('miniatm_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva5'" v-if="$t('miniatm_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva5") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva6'" v-if="$t('miniatm_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva6") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva7'" v-if="$t('miniatm_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva8'" v-if="$t('miniatm_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="heading3">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                      style="text-decoration: none; color: #003b71"
                    >
                      {{ $t("otherBank") }}
                      <span class="glyphicons glyphicons-chevron-down"></span>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapse3"
                  class="collapse"
                  aria-labelledby="heading3"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva1'" v-if="$t('otherbank_' + data.bank + '.guideva1') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva1') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide1') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva1") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva1") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva2'" v-if="$t('otherbank_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva3'" v-if="$t('otherbank_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva4'" v-if="$t('otherbank_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva5'" v-if="$t('otherbank_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva5") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva6'" v-if="$t('otherbank_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva6") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva7'" v-if="$t('otherbank_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva8'" v-if="$t('otherbank_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <!-- </div> -->
    </div>

    <div class="card my-4" style="max-width: 40em; margin: auto">
      <p class="text-center mt-4 mx-5" style="margin: auto">{{ $t("sendEmail") }}</p>
      <button
        class="btn btn-gohome btn-block"
        data-selenium-name="floating-buynow"
        @click="gobackhome()"
      >
        <h>{{ $t("gobackhome") }}</h>
      </button>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import useClipboard from "vue-clipboard3";
import { policyObject } from "./data/PolicyObject";

export default {
  name: "review",
  mixins: [Mixin],
  setup() {
    const { toClipboard } = useClipboard();

    const copy = async (bank) => {
      try {
        await toClipboard(bank);
        // this.$bvToast.toast(bank, {
        //   title: title,
        //   autoHideDelay: 5000,
        //   appendToast: false
        // })
        // console.log(bank)
      } catch (e) {
        console.error(e);
      }
    };

    return { copy };
  },
  data() {
    return {
      Global,
      policyObject,
      rating: {
        overall: 0,
        website: 0,
        service: 0,
        price: 0,
      },
    };
  },
  mounted() {
    var vm = this;
    window.scrollTo(0, 0);
    //Global.quote.Start_date = "11/06/2021";
    this.getVirtualAccount();
  },
  methods: {
    gobackhome() {
      location.reload();
    },
    changeBank(bank, prefix, prefix2, customize) {
      Global.VAinfo.bank = bank;
      Global.VAinfo.accountNumber =
        prefix + prefix2 + this.policyObject.root.quoteData.opportunityNumber.substr(-customize);
    },
  },
  computed: {
    policyStartDate: function () {
      if (!Global.quote.Start_date) {
        return { d: "", m: "", y: "" };
      }

      var dmy = Global.quote.Start_date.replace(/[\/\-]/g, "-").split("-");
      if (!dmy || dmy.length != 3) {
        return { d: "", m: "", y: "" };
      }

      return { d: dmy[0], m: dmy[1], y: dmy[2] };
    },
    countVA: function () {
      return Global.responseCreateVa.length == 1
        ? 12
        : Global.responseCreateVa.length == 2
        ? 6
        : Global.responseCreateVa.length >= 3
        ? 4
        : 4;
    },
  },
};
</script>
<i18n>
{
  "en": {
    "minimumVAPayment": "Some banks have a minimum transfer amount (i.e., Rp. 10.000). Please check with your bank on your minimum transfer limit.",
    "paymentConfirm": "Waiting your payment.",
    "policyStart": "Thank you for your interest in Roojai! ",
    "policyStartPA": "Thank you for your interest in Roojai! ",
    "policyStart1" : "Please finish your payment with ",
    "policyStart2":"Virtual Account code below",
    "howtoPayment" : "Check the payment steps below",
    "chooseBank" : "Select your bank",
    "AccountNumber" : "Virtual Account Number",
    "amount" : "Amount to be paid",
    "copy": "Copy",
    "otherBank" : "Other Banks",
    "paymentATM" : "Payment via ATM Bank",
    "sendEmail" : "You will receive welcome email from Roojai once your payment is confirmed",
    "shareExpirience": "Share your experience",
    "gobackhome": "Back to Homepage",
    "feedback": "Your feedback",
    "fblogin": "Login with Facebook",
    "fblogout": "Logout",
    "idr" : "IDR",
    "rating": {
        "overall": "Overall rating",
        "website": "Website",
        "callCenter": "Customer service",
        "price": "Product and price"
    },
    "thankForFeedback": "Thank you for your feedback.",
    "submit": "Submit",
    "policyStart_PA_installment": "Thank you for your payment. You are almost done, just one more step. Roojai call centre will contact you to confirm the policy details within 24 hours.",
    "mbanking_BCA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"m-BCA",
          "boldguide2":"y",
          "guideva3":"m-Transfer > BCA Virtual Account",
          "boldguide3":"y",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"Confirm",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Log in to BCA Mobile app.",
      "guideva2":"Select menu {0}, and enter access code to m-BCA.",
      "guideva3":"Select {0}.",
      "guideva4":"You can choose from Transfer List or enter XENDIT's virtual account number {0} and your policy number (for example : {1}123456).",
      "guideva5":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct select {0}.",
      "guideva6":"Enter your m-BCA pin.",
      "guideva7":"Your transaction is complete! Please keep the notification as a proof of your transaction.",
      "guideva8":"",
    },
    "mbanking_MANDIRI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"XENDIT's",
          "boldguide3":"y",
          "guideva4":"confirm",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Login to Livin' by Mandiri mobile app.",
      "guideva2":"Select Transfer or Payment.",
      "guideva3":"Enter {0} virtual account code {1} along with your policy number (for example: {2}123456).",
      "guideva4":"Check the data on screen, if the details are correct, select {0}.",
      "guideva5":"Enter your PIN Livin' by Mandiri mobile app.",
      "guideva6":"Your transaction is complete! Please keep your receipt as payment proof.",
      "guideva7":"",
      "guideva8":"",
    },
    "mbanking_BNI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transfer > Virtual Account Billing",
          "boldguide3":"y",
          "guideva4":"XENDIT’s",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Login to your BNI Mobile Banking on your phone.",
      "guideva2":"Enter your User ID and password.",
      "guideva3":"Select {0}.",
      "guideva4":"Enter {0} virtual account code ({1}) and your policy number (for example: {2}123456).",
      "guideva5":"Check your payment detail. If the detail is correct, enter your transaction password.",
      "guideva6":"Your transaction is complete! Please keep the notification as a proof of your transaction.",
      "guideva7":"",
      "guideva8":"",
    },
    "mbanking_PERMATA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Pay Bills > Virtual Account",
          "boldguide2":"y",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Access your PermataMobile X application and enter your Password.",
      "guideva2":"Select {0}.",
      "guideva3":"Enter Xendit Virtual Account Number ({0}) and your policy number (for example: {1}123456).",
      "guideva4":"Your billing detail will show on the screen.",
      "guideva5":"Choose your source of payment.",
      "guideva6":"Enter SMS token response.",
      "guideva7":"Transaction completed.",
      "guideva8":"",
    },
    "mbanking_BRI": {
      "param":[
        {
          "guideva1":"BRI Mobile Banking",
          "boldguide1":"y",
          "guideva2":"Info > BRIVA",
          "boldguide2":"y",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Login to the {0} application.",
      "guideva2":"Select {0}.",
      "guideva3":"Enter your Xendit BRI Virtual Account Number ({0}) and your policy number (Example: {1}123456).",
      "guideva4":"Check your billing details.",
      "guideva5":"If the details are correct, enter BRI Mobile/SMS Banking PIN to process.",
      "guideva6":"You will receive payment notification via SMS.",
      "guideva7":"",
      "guideva8":"",
    },
    "internetBanking_BCA": {
      "param":[
        {
          "guideva1":"https://ibank.klikbca.com/",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transfer > Transfer to BCA Virtual Account",
          "boldguide3":"y",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"Continue",
          "boldguide5":"y",
          "guideva6":"Send",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://ibank.klikbca.com/",
      "guideva1":"Log in to KlikBCA Invidual app ({0}).",
      "guideva2":"Enter User ID and PIN.",
      "guideva3":"Select {0}.",
      "guideva4":"Enter Xendit's virtual account number {0} and your policy number. (for example : {1}123456).",
      "guideva5":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct select {0}.",
      "guideva6":"Input your \"keyBCA Response\" and click {0}.",
      "guideva7":"Your transaction is complete! Print the reference number as proof of your transaction.",
      "guideva8":"",
      "guideva9":"",
    },
    "internetBanking_MANDIRI": {
      "param":[
        {
          "guideva1":"https://ibank.bankmandiri.co.id/",
          "boldguide1":"",
          "guideva2":"Payment > Multipayment",
          "boldguide2":"y",
          "guideva3":"Xendit",
          "boldguide3":"y",
          "guideva4":"Next",
          "boldguide4":"y",
          "guideva5":"Confirm",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://ibank.bankmandiri.co.id/",
      "guideva1":"Go to Mandiri Online website ({0}), username dan password.",
      "guideva2":"Select menu {0}.",
      "guideva3":"Select Service Provider {0} {1}.",
      "guideva4":"Input Virtual Account number, then click {0}.",
      "guideva5":"Check the data on screen. If all details are correct, select {0}.",
      "guideva6":"You are required to approve your payment on Livin' by Mandiri mobile app.",
      "guideva7":"",
      "guideva8":"",
      "guideva9":"",
    },
    "internetBanking_BNI": {
      "param":[
        {
          "guideva1":"https://ibank.bni.co.id/",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Virtual Account Billing",
          "boldguide3":"y",
          "guideva4":"XENDIT’s",
          "boldguide4":"y",
          "guideva5":"Continue",
          "boldguide5":"y",
          "guideva6":"Continue",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://ibank.bni.co.id/",
      "guideva1":"Login to {0}",
      "guideva2":"Enter your User ID and password.",
      "guideva3":"Select {0}.",
      "guideva4":"Enter {0} virtual account code ({1}) and your policy number (for example: {2}123456).",
      "guideva5":"Choose the type of account you are using to transfer, then select {0}.",
      "guideva6":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct click {0}.",
      "guideva7":"Input the authentication token code.",
      "guideva8":"Your transaction is complete!",
      "guideva9":"",
    },
    "internetBanking_PERMATA": {
      "param":[
        {
          "guideva1":"https://www.permatanet.com/",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Payment > Billing payment > Virtual Account",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"prefix",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"prefix",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://www.permatanet.com/",
      "guideva1":"Open the {0} website.",
      "guideva2":"Enter User ID & Password.",
      "guideva3":"Choose {0}.",
      "guideva4":"Choose your source of payment.",
      "guideva5":"Choose “add new billing”.",
      "guideva6":"Enter Xendit Virtual Account Number ({0}) and your policy number (for example: {1}123456).",
      "guideva7":"Check your billing detail.",
      "guideva8":"Enter SMS token response code.",
      "guideva9":"Transaction completed.",
    },
    "internetBanking_BRI": {
      "param":[
        {
          "guideva1":"https://ib.bri.co.id/ib-bri/",
          "boldguide1":"",
          "guideva2":"Payment menu > BRIVA",
          "boldguide2":"y",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://ib.bri.co.id/ib-bri/",
      "guideva1":"Login to the BRI Internet Banking page {0}",
      "guideva2":"Select the {0}.",
      "guideva3":"Enter your Xendit BRI Virtual Account Number ({0}) and your policy number (Example: {1}123456).",
      "guideva4":"Enter BRI Internet Banking password.",
      "guideva5":"Enter BRI Internet Banking mToken.",
      "guideva6":"You will get a payment notification.",
      "guideva7":"",
      "guideva8":"",
      "guideva9":"",
    },
    "atmbank_BCA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Insert your card and enter your PIN number.",
      "guideva2":"Select Other Transaction > Transfer > To BCA Virtual Account.",
      "guideva3":"Enter XENDIT's virtual account number {0} and your policy number. (for example : {1}123456).",
      "guideva4":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct select yes.",
      "guideva5":"Your transaction is complete! Please keep your receipt as a proof of your transaction.",
      "guideva6":"",
      "guideva7":"",
      "guideva8":"",
    },
    "atmbank_MANDIRI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Payment/Purchase",
          "boldguide2":"y",
          "guideva3":"Others",
          "boldguide3":"y",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"Correct",
          "boldguide5":"y",
          "guideva6":"“1”",
          "boldguide6":"y",
          "guideva7":"Yes",
          "boldguide7":"y",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Multipayment",
          "boldguide3":"y",
          "guideva4":"Correct",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"Yes",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Insert your card and enter your PIN.",
      "guideva2":"Select {0}.",
      "guideva3":"Select {0}, then select {1}.",
      "guideva4":"Input Xendit's code {0}, and choose {1}.",
      "guideva5":"Input your virtual account number, and choose {0}.",
      "guideva6":"Check the data on screen. If correct, press {0} and choose {1}.",
      "guideva7":"Check the data on screen again and choose {0}.",
      "guideva8":"Your transaction is complete! Keep your receipt for proof of payment.",
    },
    "atmbank_BNI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Other > Transfer > Saving Account",
          "boldguide2":"y",
          "guideva3":"Virtual Account Billing",
          "boldguide3":"y",
          "guideva4":"XENDIT’s",
          "boldguide4":"y",
          "guideva5":"Yes",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Insert your card and enter PIN number.",
      "guideva2":"Select menu {0}.",
      "guideva3":"Select {0}.",
      "guideva4":"Enter {0} virtual account code ({1}) and your policy number (for example: {2}123456).",
      "guideva5":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct select {0}.",
      "guideva6":"Transaction complete! Please keep your receipt as a proof of your payment.",
      "guideva7":"",
      "guideva8":"",
    },
    "atmbank_PERMATA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Other payments > Virtual Account",
          "boldguide3":"y",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"correct",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Insert your Permata ATM card & PIN.",
      "guideva2":"On the main menu, select Other Transactions.",
      "guideva3":"Select Payment > {0}.",
      "guideva4":"Enter your Xendit Virtual Account number ({0}) and your policy number (example: {1}123456).",
      "guideva5":"The amount to be paid and the account number will appear on the payment confirmation page. If the information is correct, select {0}.",
      "guideva6":"",
      "guideva7":"",
      "guideva8":"",
    },
    "atmbank_BRI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Other Transaction > Payment > Others > BRIVA",
          "boldguide2":"y",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Insert your BRI ATM card and PIN.",
      "guideva2":"Select {0}.",
      "guideva3":"Enter your Xendit BRI Virtual Account Number ({0}) and your policy number (Example: {1}123456).",
      "guideva4":"Check your billing details.",
      "guideva5":"If the details are correct, select Yes to process the payment.",
      "guideva6":"",
      "guideva7":"",
      "guideva8":"",
    },
    "miniatm_BRI": {
      "param":[
        {
          "guideva1":"Mini ATM menu",
          "boldguide1":"y",
          "guideva2":"Payment > BRIVA",
          "boldguide2":"y",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"Next",
          "boldguide6":"y",
          "guideva7":"Yes",
          "boldguide7":"y",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Select {0}.",
      "guideva2":"Select {0}.",
      "guideva3":"Swipe ATM card.",
      "guideva4":"Enter your Xendit BRI Virtual Account Number ({0}) and your policy number (Example: {1}123456).",
      "guideva5":"Enter ATM pin.",
      "guideva6":"Click {0} to process payment.",
      "guideva7":"Click {0} to print the receipt.",
      "guideva8":"",
    },
    "otherbank_BCA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Other Transaction > Transfer > Other Bank",
          "boldguide3":"y",
          "guideva4":"014",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Get your BCA VA number.",
      "guideva2":"Insert your card and enter your PIN number or your mBanking PIN number.",
      "guideva3":"Select {0}.",
      "guideva4":"Select to BCA Bank or input the bank code {0}.",
      "guideva5":"Enter the amount to be paid.",
      "guideva6":"Check the data on the screen. If the details are correct, complete your transaction.",
      "guideva7":"",
      "guideva8":"",
    },
    "otherbank_MANDIRI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Other Transaction > Transfer > Other Bank",
          "boldguide3":"y",
          "guideva4":"008",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Get your Mandiri VA number.",
      "guideva2":"Insert your card and enter your PIN number or your mBanking PIN number.",
      "guideva3":"Select {0}.",
      "guideva4":"Select to Mandiri Bank or input the bank code {0}.",
      "guideva5":"Enter the amount to be paid.",
      "guideva6":"Check the data on the screen. If the details are correct, complete your transaction.",
      "guideva7":"",
      "guideva8":"",
    },
    "otherbank_BNI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Other Transaction > Transfer > Other Bank",
          "boldguide3":"y",
          "guideva4":"009",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Get your BNI VA number.",
      "guideva2":"Insert your card and enter your PIN number or your mBanking PIN number.",
      "guideva3":"Select {0}.",
      "guideva4":"Select to BNI Bank or input the bank code {0}.",
      "guideva5":"Enter the amount to be paid.",
      "guideva6":"Check the data on the screen. If the details are correct, complete your transaction.",
      "guideva7":"",
      "guideva8":"",
    },
    "otherbank_PERMATA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Transfer",
          "boldguide2":"y",
          "guideva3":"Select Permata Bank or",
          "boldguide3":"y",
          "guideva4":"Enter the amount to be paid",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Transfer to Other Banks",
          "boldguide2":"y",
          "guideva3":"013",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Get your Permata VA number.",
      "guideva2":"Select the {0} menu > {1}.",
      "guideva3":"{0} enter Permata Bank code ({1}) and payment code ({2}123456).",
      "guideva4":"{0}.",
      "guideva5":"Check the data on the screen. If the details are correct, complete your transaction.",
      "guideva6":"",
      "guideva7":"",
      "guideva8":"",
    },
    "otherbank_BRI": {
      "param":[
        {
          "guideva1":"BRI",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transfer menu > Transfer to Other Banks",
          "boldguide3":"y",
          "guideva4":"Select BRI bank or",
          "boldguide4":"y",
          "guideva5":"Enter the amount to be paid",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"002",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Get your VA {0} number.",
      "guideva2":"Insert you ATM card and enter your PIN or mBanking PIN number",
      "guideva3":"Select the {0}.",
      "guideva4":"{0} enter BRI bank code ({1}) and payment code ({2}123456).",
      "guideva5":"{0}.",
      "guideva6":"Check the data on the screen. If the details are correct, complete your transaction.",
      "guideva7":"",
      "guideva8":"",
    },
  },
  "id": {
    "minimumVAPayment": "Beberapa bank memiliki minimum transfer (i.e, Rp. 10.000). Mohon cek limit minimum transfer bank anda.",
    "paymentConfirm": "Menunggu pembayaran kamu",
    "policyStart": "Terimakasih atas ketertarikanmu pada Roojai! ",
    "policyStartPA": "Terimakasih atas ketertarikanmu pada Roojai! ",
    "policyStart1" : "Mohon melakukan pembayaran dengan menggunakan ",
    "policyStart2":"kode virtual account di bawah ini.",
    "howtoPayment" : "Cek cara pembayaran di bawah ini",
    "chooseBank" : "Silakan pilih bank yang kamu miliki",
    "sendEmail" : "Kamu akan menerima email konfirmasi dari Roojai, jika pembayaran sudah kami terima",
    "shareExpirience": "Bagikan pengalaman kamu",
    "AccountNumber" : "Nomor Virtual Account",
    "amount" : "Nominal yang harus di bayar",
    "copy": "Salin",
    "otherBank" : "Bank Lain",
    "paymentATM" : "Pembayaran melalui ATM bank",
    "idr" : "Rp. ",
    "gobackhome": "Kembali ke Halaman Awal",
    "feedback": "Ulasan kamu",
    "fblogin": "Masuk dengan Facebook",
    "fblogout": "Keluar",
    "rating": {
        "overall": "Penilaian keseluruhan",
        "website": "Website",
        "callCenter": "Pelayanan pelanggan",
        "price": "Produk dan harga"
    },
    "thankForFeedback": "Terimakasih atas masukan yang diberikan.",
    "submit": "Kirim",
    "policyStart_PA_installment": "Terimakasih atas pembayaran yang dilakukan. Kamu hampir selesai dalam satu langkah lagi. Roojai call centre akan menghubungi kamu untuk mengonfirmasi terkait detail polis dalam waktu 24 jam.",
    "mbanking_BCA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"m-BCA",
          "boldguide2":"y",
          "guideva3":"m-Transfer > BCA Virtual Account",
          "boldguide3":"y",
          "guideva4":"XENDIT",
          "boldguide4":"y",
          "guideva5":"Konfirmasi",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Lakukan log in pada aplikasi BCA Mobile.",
      "guideva2":"Pilih menu {0}, kemudian masukkan kode akses m-BCA.",
      "guideva3":"Pilih {0}.",
      "guideva4":"Pilih dari Daftar Transfer, atau masukkan nomor {0} {1} dan Nomor Polis kamu (Contoh: {2}123456).",
      "guideva5":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih {0}.",
      "guideva6":"Masukkan pin m-BCA kamu.",
      "guideva7":"Transaksi selesai! Kamu bisa menyimpan notifikasi yang muncul sebagai bukti pembayaran.",
      "guideva8":"",
    },
    "mbanking_MANDIRI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Xendit",
          "boldguide3":"y",
          "guideva4":"Konfirmasi",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Login Aplikasi Livin' by Mandiri.",
      "guideva2":"Pilih menu Transfer/Bayar.",
      "guideva3":"Masukkan kode virtual account {0} {1}, dan nomor polis kamu (contoh: {2}123456).",
      "guideva4":"Cek detail pembayaran. Jika detail pembayaran sudah benar, pilih {0}.",
      "guideva5":"Masukkan PIN aplikasi Livin' by Mandiri.",
      "guideva6":"Transaksi selesai! Simpan struk transaksi kamu sebagai bukti pembayaran.",
      "guideva7":"",
      "guideva8":"",
    },
    "mbanking_BNI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transfer > Virtual Account Billing",
          "boldguide3":"y",
          "guideva4":"XENDIT",
          "boldguide4":"y",
          "guideva5":"Lanjut",
          "boldguide5":"y",
          "guideva6":"Lanjutkan",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Akses BNI Mobile Banking melalui handphone.",
      "guideva2":"Masukkan User ID dan password.",
      "guideva3":"Pilih menu {0}.",
      "guideva4":"Masukkan kode virtual account {0} ({1}) dan nomor polis kamu (Contoh: {2}123456).",
      "guideva5":"Cek detail pembayaran dan masukkan password mobile banking kamu.",
      "guideva6":"Transaksi selesai! Kamu bisa menyimpan notifikasi yang muncul sebagai bukti pembayaran.",
      "guideva7":"",
      "guideva8":"",
    },
    "mbanking_PERMATA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Bayar Tagihan > Virtual Account",
          "boldguide2":"y",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Buka aplikasi PermataMobile X dan masukkan Password kamu.",
      "guideva2":"Pilih {0}.",
      "guideva3":"Masukkan Nomor Virtual Account Xendit ({0}) dan nomor polis kamu (Contoh: {1}123456).",
      "guideva4":"Detail tagihan akan muncul dilayar.",
      "guideva5":"Pilih sumber pembayaran.",
      "guideva6":"Masukan SMS token response.",
      "guideva7":"Transaksi selesai.",
      "guideva8":"",
    },
    "mbanking_BRI": {
      "param":[
        {
          "guideva1":"Mobile Banking BRI",
          "boldguide1":"y",
          "guideva2":"Info > BRIVA",
          "boldguide2":"y",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Login pada aplikasi {0}.",
      "guideva2":"Pilih menu {0}.",
      "guideva3":"Masukkan Nomor Virtual Account Xendit BRI ({0}) dan nomor polis kamu (Contoh: {1}123456).",
      "guideva4":"Cek detail tagihan dan pastikan tagihan kamu sudah benar.",
      "guideva5":"Masukkan PIN Mobile/SMS Banking BRI.",
      "guideva6":"Anda akan mendapatkan notifikasi pembayaran melalui SMS.",
      "guideva7":"",
      "guideva8":"",
    },
    "internetBanking_BCA": {
      "param":[
        {
          "guideva1":"https://ibank.klikbca.com/",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transfer Dana > Transfer ke BCA Virtual Account",
          "boldguide3":"y",
          "guideva4":"XENDIT",
          "boldguide4":"y",
          "guideva5":"Lanjutkan",
          "boldguide5":"y",
          "guideva6":"Kirim",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://ibank.klikbca.com/",
      "guideva1":"Lakukan log in pada aplikasi KlikBCA Individual ({0}).",
      "guideva2":"Masukkan User ID dan PIN.",
      "guideva3":"Pilih {0}.",
      "guideva4":"Masukkan nomor BCA nomor virtual account {0} {1} dan Nomor Polis kamu (Contoh: {2}123456).",
      "guideva5":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih {0}.",
      "guideva6":"Masukkan nomor dari token kamu dan klik {0}.",
      "guideva7":"Transaksi selesai! Cetak nomor referensi sebagai bukti transaksi kamu.",
      "guideva8":"",
      "guideva9":"",
    },
    "internetBanking_MANDIRI": {
      "param":[
        {
          "guideva1":"https://ibank.bankmandiri.co.id/",
          "boldguide1":"",
          "guideva2":"Pembayaran > Multipayment",
          "boldguide2":"y",
          "guideva3":"Xendit",
          "boldguide3":"y",
          "guideva4":"Lanjut",
          "boldguide4":"y",
          "guideva5":"Konfirmasi",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://ibank.bankmandiri.co.id/",
      "guideva1":"Akses situs Mandiri Online ({0}), masukkan username dan password.",
      "guideva2":"Pilih menu {0}.",
      "guideva3":"Pilih Penyedia Jasa {0} {1}.",
      "guideva4":"Masukkan nomor virtual account Mandiri dan klik {0}.",
      "guideva5":"Layar akan menampilkan konfirmasi. Apabila sesuai, lanjutkan dengan pilih {0}.",
      "guideva6":"Kamu diharuskan melakukan persetujuan pembayaran diaplikasi Livin' by Mandiri di mobile phone.",
      "guideva7":"",
      "guideva8":"",
      "guideva9":"",
    },
    "internetBanking_BNI": {
      "param":[
        {
          "guideva1":"https://ibank.bni.co.id/",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Virtual Account Billing",
          "boldguide3":"y",
          "guideva4":"XENDIT",
          "boldguide4":"y",
          "guideva5":"Lanjut",
          "boldguide5":"y",
          "guideva6":"Lanjutkan",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://ibank.bni.co.id/",
      "guideva1":"Akses {0}",
      "guideva2":"Masukkan User ID dan password.",
      "guideva3":"Pilih {0}.",
      "guideva4":"Masukkan kode virtual account {0} ({1}) dan nomor polis kamu (Contoh: {2}123456).",
      "guideva5":"Pilih rekening yang kamu gunakan, lalu pilih {0}.",
      "guideva6":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih {0}.",
      "guideva7":"Masukkan Kode Otentikasi Token.",
      "guideva8":"Transaksi selesai!",
      "guideva9":"",
    },
    "internetBanking_PERMATA": {
      "param":[
        {
          "guideva1":"https://www.permatanet.com/",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Pembayaran Tagihan > Virtual Account",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"XENDIT",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"prefix",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"prefix",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://www.permatanet.com/",
      "guideva1":"Buka webitse {0}",
      "guideva2":"Masukan User ID & Password.",
      "guideva3":"Pilih {0}.",
      "guideva4":"Pilih sumber pembayaran.",
      "guideva5":"Pilih menu “masukkan daftar tagihan baru”.",
      "guideva6":"Masukkan kode virtual account {0} ({1}) dan nomor polis kamu (Contoh: {2}123456).",
      "guideva7":"Cek detail tagihan dan pastikan sudah sesuai.",
      "guideva8":"Masukan SMS token response.",
      "guideva9":"Transaksi selesai.",
    },
    "internetBanking_BRI": {
      "param":[
        {
          "guideva1":"https://ib.bri.co.id/ib-bri/",
          "boldguide1":"",
          "guideva2":"Pembayaran > BRIVA",
          "boldguide2":"y",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"https://ib.bri.co.id/ib-bri/",
      "guideva1":"Login pada halaman Internet Banking BRI {0}",
      "guideva2":"Pilih menu {0}.",
      "guideva3":"Masukkan Nomor Virtual Account Xendit BRI ({0}) dan nomor polis kamu (Contoh: {1}123456).",
      "guideva4":"Masukkan password Internet Banking BRI.",
      "guideva5":"Masukkan mToken Internet Banking BRI.",
      "guideva6":"Anda akan mendapatkan notifikasi pembayaran.",
      "guideva7":"",
      "guideva8":"",
      "guideva9":"",
    },
    "atmbank_BCA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"XENDIT",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Masukkan Kartu ATM BCA & PIN.",
      "guideva2":"Pilih menu Transaksi Lainnya > Transfer > ke Rekening BCA Virtual Account.",
      "guideva3":"Masukkan nomor virtual account {0} {1} dan Nomor Polis kamu (Contoh: {2}123456).",
      "guideva4":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih ya.",
      "guideva5":"Transaksi selesai! Simpan struk transaksi kamu sebagai bukti pembayaran.",
      "guideva6":"",
      "guideva7":"",
      "guideva8":"",
    },
    "atmbank_MANDIRI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Bayar/Beli",
          "boldguide2":"y",
          "guideva3":"Lainnya",
          "boldguide3":"y",
          "guideva4":"Xendit",
          "boldguide4":"y",
          "guideva5":"Benar",
          "boldguide5":"y",
          "guideva6":"“1”",
          "boldguide6":"y",
          "guideva7":"Ya",
          "boldguide7":"y",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Multipayment",
          "boldguide3":"y",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"Ya",
          "boldguide6":"y",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"Benar",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Masukkan kartu ATM dan Pin.",
      "guideva2":"Pilih Menu {0}.",
      "guideva3":"Pilih menu {0}, hingga menemukan menu {1}.",
      "guideva4":"Masukkan kode {0} {1}, lalu pilih {2}.",
      "guideva5":"Masukkan nomor virtual account kamu, lalu pilih tombol {0}.",
      "guideva6":"Masukkan angka {0} untuk memilih tagihan, lalu pilih tombol {1}.",
      "guideva7":"Akan muncul konfirmasi pembayaran, lalu pilih tombol {0}.",
      "guideva8":"Transaksi selesai! Kamu bisa simpan struk sebagai bukti pembayaran kamu.",
    },
    "atmbank_BNI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Lainnya > Transfer > Rekening Tabungan",
          "boldguide2":"y",
          "guideva3":"Virtual Account Billing",
          "boldguide3":"y",
          "guideva4":"XENDIT",
          "boldguide4":"y",
          "guideva5":"Ya",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Masukkan kartu dan pin ATM kamu.",
      "guideva2":"Pilih Menu {0}.",
      "guideva3":"Pilih {0}.",
      "guideva4":"Masukkan kode virtual account {0} ({1}) dan nomor polis kamu (Contoh: {2}123456).",
      "guideva5":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih {0}.",
      "guideva6":"Transaksi selesai! Simpan struk transaksi kamu sebagai bukti pembayaran.",
      "guideva7":"",
      "guideva8":"",
    },
    "atmbank_PERMATA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Transaksi Lainnya",
          "boldguide2":"y",
          "guideva3":"Pembayaran > Pembayaran lainnya > Virtual Account",
          "boldguide3":"y",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"Benar",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Masukkan kartu ATM Permata & PIN kamu.",
      "guideva2":"Pada menu utama, pilih {0}.",
      "guideva3":"Pilih menu {0}.",
      "guideva4":"Masukkan nomor Virtual Account Xendit ({0}) dan nomor polis kamu (Contoh: {1}123456).",
      "guideva5":"Jumlah yang harus dibayar dan nomor rekening akan muncul pada halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih {0}.",
      "guideva6":"",
      "guideva7":"",
      "guideva8":"",
    },
    "atmbank_BRI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Transaksi Lain > Pembayaran > Lainnya > BRIVA",
          "boldguide2":"y",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"Ya",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Masukkan kartu ATM BRI dan PIN kamu.",
      "guideva2":"Pilih menu {0}.",
      "guideva3":"Masukkan Nomor Virtual Account Xendit BRI ({0}) dan nomor polis kamu (Contoh: {1}123456).",
      "guideva4":"Cek detail tagihan dan pastikan detail tagihan kamu sudah benar.",
      "guideva5":"Pilih {0} untuk memproses pembayaran.",
      "guideva6":"",
      "guideva7":"",
      "guideva8":"",
    },
    "miniatm_BRI": {
      "param":[
        {
          "guideva1":"Mini ATM",
          "boldguide1":"y",
          "guideva2":"Pembayaran > BRIVA",
          "boldguide2":"y",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"y",
          "guideva6":"Lanjut",
          "boldguide6":"y",
          "guideva7":"Ya",
          "boldguide7":"y",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Pilih menu {0}.",
      "guideva2":"Pilih menu {0}.",
      "guideva3":"Swipe kartu ATM.",
      "guideva4":"Masukkan Nomor Virtual Account Xendit BRI ({0}) dan nomor polis kamu (Contoh: {1}123456).",
      "guideva5":"Masukkan pin ATM.",
      "guideva6":"Klik {0} untuk memproses pembayaran.",
      "guideva7":"Klik {0} untuk mencetak struk.",
      "guideva8":"",
    },
    "otherbank_BCA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transaksi Lain > Transfer > Bank lain",
          "boldguide3":"y",
          "guideva4":"014",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Salin nomor VA BCA kamu.",
      "guideva2":"Masukkan kartu ATM dan PIN atau PIN mBanking kamu.",
      "guideva3":"Pilih {0}.",
      "guideva4":"Pilih bank BCA atau masukkan kode bank {0}.",
      "guideva5":"Masukkan nominal yang harus dibayarkan.",
      "guideva6":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
      "guideva7":"",
      "guideva8":"",
    },
    "otherbank_MANDIRI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transaksi Lain > Transfer > Bank lain",
          "boldguide3":"y",
          "guideva4":"008",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Dapatkan nomor VA Mandiri kamu.",
      "guideva2":"Masukkan kartu ATM dan PIN atau PIN mBanking kamu.",
      "guideva3":"Pilih {0}.",
      "guideva4":"Pilih bank Mandiri atau masukkan kode bank {0}.",
      "guideva5":"Masukkan nominal yang harus dibayarkan.",
      "guideva6":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
      "guideva7":"",
      "guideva8":"",
    },
    "otherbank_BNI": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transaksi Lain > Transfer > Bank lain",
          "boldguide3":"y",
          "guideva4":"009",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Dapatkan nomor VA BNI kamu.",
      "guideva2":"Masukkan kartu ATM dan PIN atau PIN mBanking kamu.",
      "guideva3":"Pilih {0}.",
      "guideva4":"Pilih bank BNI atau masukkan kode bank {0}.",
      "guideva5":"Masukkan nominal yang harus dibayarkan.",
      "guideva6":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
      "guideva7":"",
      "guideva8":"",
    },
    "otherbank_PERMATA": {
      "param":[
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"Transfer > Transfer ke Bank Lain",
          "boldguide2":"y",
          "guideva3":"Pilih Bank Permata atau",
          "boldguide3":"y",
          "guideva4":"Masukkan nominal yang harus dibayarkan",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"013",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"prefix",
          "boldguide3":"y",
          "guideva4":"",
          "boldguide4":"",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Dapatkan nomor VA permata kamu.",
      "guideva2":"Pilih menu {0}.",
      "guideva3":"{0} masukan kode Permata Bank ({1}) dan kode bayar ({2}123456).",
      "guideva4":"{0}.",
      "guideva5":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
      "guideva6":"",
      "guideva7":"",
      "guideva8":"",
    },
    "otherbank_BRI": {
      "param":[
        {
          "guideva1":"BRI",
          "boldguide1":"y",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"Transfer > Transfer ke Bank Lain",
          "boldguide3":"y",
          "guideva4":"Pilih bank BRI atau",
          "boldguide4":"y",
          "guideva5":"Masukkan nominal yang harus dibayarkan",
          "boldguide5":"y",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"BRI (002)",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        },
        {
          "guideva1":"",
          "boldguide1":"",
          "guideva2":"",
          "boldguide2":"",
          "guideva3":"",
          "boldguide3":"",
          "guideva4":"prefix",
          "boldguide4":"y",
          "guideva5":"",
          "boldguide5":"",
          "guideva6":"",
          "boldguide6":"",
          "guideva7":"",
          "boldguide7":"",
          "guideva8":"",
          "boldguide8":"",
        }
      ],
      "link":"",
      "guideva1":"Dapatkan nomor VA {0} kamu.",
      "guideva2":"Masukkan kartu ATM dan nomor PIN kamu atau PIN mBanking kamu.",
      "guideva3":"Pilih menu {0}.",
      "guideva4":"{0} masukan kode {1} dan nomor polis kamu (Contoh: {2}123456).",
      "guideva5":"{0}.",
      "guideva6":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
      "guideva7":"",
      "guideva8":"",
    },
  },
}
</i18n>