<style>
.fullPaymentOnly {
  width: 100% !important;
}

#illness-payment #payment-accept-modal .scrollbar-default {
  scrollbar-width: thin;
  scrollbar-color: #ddd orange;
}
#illness-payment #payment-accept-modal .scrollbar-default::-webkit-scrollbar {
  width: 12px;
}
#illness-payment
  #payment-accept-modal
  .scrollbar-default::-webkit-scrollbar-track {
  background: #cecece;
  border-radius: 20px;
}
#illness-payment
  #payment-accept-modal
  .scrollbar-default::-webkit-scrollbar-thumb {
  background-color: orange;
  border-radius: 20px;
}

#illness-payment #payment-accept-modal #disclaimer {
  height: 60vh;
  overflow-y: auto;
  font-size: 14px;
}

#illness-payment #payment-accept-modal #disclaimer > div {
  text-indent: 20px;
}

.btn.disabled, .btn:disabled{
  border: 1px solid #ccc !important;
  color: #ccc !important;
  background-color: #FFF !important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 15%) !important;
}

@media (max-width: 450px) {
  .h1section-text2.h4 {
    font-size: 18px;
  }
  body.default-style .insure-modal .modal-content .modal-footer button {
    font-size: 14px;
  }
  .btn-choose{
    word-spacing: 9999px;
  }
}


.tooltip-pymt {
  position: relative;
  display: inline-block;
}

.tooltip-pymt .tooltiptext-pymt {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}
.nav-tabs{
  border-bottom: 3px solid #dee2e6;
}
.nav-tabs .nav-item{
  margin-bottom: -3px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  border: 3px solid transparent;
}
.nav-link.active{
  color: #01549b !important;
}
.nav-link{
  color: #999 !important;
}
body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs .nav-item .nav-link.active:before{
  content: none;
}
.btn-choose.active{
  border-radius: 5px !important;
  color: #fff !important;
  background-color: #01549b !important;
  border: 1px solid #01549b !important;
  box-shadow: none;
  background-image: none;
}
.btn-choose{
  border-radius: 5px !important;
  color: #003b71 !important;
  border: 1px solid #ccc;
  box-shadow: none;
  background-image: none;
}
body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency {
  margin:0 auto;
  padding:10px;
  background-color:#ffffff;
  border-radius:.25rem;
  border:1px solid rgba(221,221,221,100%)
  /* #dcebf5 */
}

body.default-style #illness-payment .payment-method-content .price-card{
  border:none;
  background-color:#ffffff;
  border-radius:.25rem;
  max-width:500px;
  margin:0 auto
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:not(.active):hover {
  border-color:  transparent;
  color: #003b71;
}
/* body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info{
  margin:10px auto;
  border:none;
  background-image:linear-gradient(0deg,#16416a 0,#2b82d3 100%);
  color:#fff;
  box-shadow:0 2px 10px 1px rgba(0,0,0,.1);max-width:500px} */
body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info{
  margin:10px auto;
  border:none;
  background-image:linear-gradient(0deg,#dcebf5 0,#dcebf5 100%);
  background-color:#dcebf5;
  color:#003b71;
  box-shadow:0 2px 10px 1px rgba(0,0,0,.1);
  max-width:500px
}

#payment-frequency {
  width: 100%;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info .form-group #what-cvv, body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info .form-group #what-cvv:focus {
  padding: 2px 4px;
  line-height: 1;
  font-size: 10px;
  border-radius: 50%;
  background-color: transparent;
  width: 15px;
  color: #003b71;
  border-color: #fff;
}
body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info .form-group #cvv-icon{
    position: absolute;
    right: 25px;
    top: 30px;
    color: #ccc;
}
@media (max-width: 451px){
  body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info .form-group #cvv-icon{
    position: absolute;
    right: 25px;
    top: 48px;
    color: #ccc;
  }
}

#installment-detail-modal .modal-header{
  display: block;
}

#installment-detail-modal .modal-header .close{
  color: #ff5011;
}

#installment-detail-modal .installment-option {
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  border: 1px solid #ece9e8;
  color: grey
}

#installment-detail-modal .installment-option .panel-body{
  padding: 15px;
}

#installment-detail-modal .installment-option.active {
    background-color: #fff !important;
    border: 1px solid #ff5011 !important;
    box-shadow: 0 2px 5px 1px rgba(154, 154, 154, .7) !important;
    color: #003b71 !important
}
#installment-detail-modal .installment-option.active .panel-footer {
    color: #fff;
    background-color: #ff5011;
}
#installment-detail-modal .installment-option.active .btn-pk-radio {
    border: 0
}
#installment-detail-modal .installment-option.active .btn-pk-radio .glyphicons.glyphicons-ok-sign {
    display: inline
}
#installment-detail-modal .installment-option .text-head {
    font-size: 16px
}
#installment-detail-modal .installment-option .text-desc {
    font-size: 16px
}
#installment-detail-modal .installment-option .btn-pk-radio {
    width: 30px;
    height: 30px;
    border-radius: 40px;
    background-color: #fff;
    border: 1px solid rgba(255, 53, 19, .3);
    padding: 0;
    margin: 0;
    z-index: 50;
    outline-color: #fff;
    cursor: pointer
}
#installment-detail-modal .installment-option .btn-pk-radio:hover {
    box-shadow: 0 2px 5px 1px rgba(154, 154, 154, .7);
    border: 0
}
#installment-detail-modal .installment-option .btn-pk-radio:hover .glyphicons.glyphicons-ok-sign {
    display: inline;
    color: #fa7f52
}
#installment-detail-modal .installment-option .btn-pk-radio .glyphicons {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 30px
}
#installment-detail-modal .installment-option .btn-pk-radio .glyphicons.glyphicons-ok-sign {
    color: #ff5011;
    display: none
}
#installment-detail-modal .installment-option .panel-footer {
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid #ece9e8;
    font-size: 17px;
    padding: 1rem 1rem;
}
#installment-detail-modal .installment-option .panel-footer p {
    margin: 0;
    padding: 0
}


.tooltip-pymt .tooltiptext-pymt::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-pymt:hover .tooltiptext-pymt {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 370px) {
  .no-va{
    font-size: 0.8rem;
  }
  .btn{
    padding: 0.15rem 0.35rem;
  }
}
@media (max-width: 415px) {
  .img-bank{
    width: 80px;
  }
  .autodebit-body{
    padding: 0px 10px 20px 20px;
  }
  .instalment-button{
    line-height: 40px;
  }
}

@media (max-width: 500px) {
  .text-debit-card{
    word-spacing:9999px;
  }
}

.btn-epolicy{
  background-color: #ff5011;
  border-radius: 5px;
}

.btn-epolicy-no{
  color: #ff5011 !important;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ff5011 !important;
  border-color: #ff5011 !important;
  /* padding: 5px 10px; */
}
.va-method .va-link.active, .va-method .show>.va-link{
    color: #003b71;
    background-color: transparent;
    border: 3px solid #003b71;
    transition: 0.5s;
}
.va-method .va-link{
    color: #003b71;
    background-color: transparent;
    border: 3px solid #c4c4c4;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs {
  margin-left: -10px;
  margin-right: -10px;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs .nav-item {
  width: 50%;
  margin: 0;
  padding: 0 10px;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs .nav-item:first-child {
  margin: 0;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs .nav-item .nav-link {
    padding-top: 16px;
    padding-bottom: 16px; 
}

.autodebit-body{
  padding: 5px 10px 20px 20px !important;
}

.alert-payment{
  background: #EFF8FF;
  border: 1px solid #89CBFF;
  border-radius: 8px 16px;
  margin-right: 40px;
  margin-left: 40px;
}
.img-msg-payment{
  height: 24px;
  width: 24px;
  margin-top: 6px;
}
.msg-payment{
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #424242;
    margin-left: 12px;
    margin-top: 4px;
    margin-bottom: 4px;

}
</style>

<template>
  <div id="illness-payment" class="center-container">
    <!-- <div class="blue-bg" v-if="policyObject.root.quoteData.opportunityNumber == ''">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 text-center pt-5">
                        <h3 class="text-center mb-5">{{$t("errorTBAtitle")}}</h3>
                        <div class="thank2you">
                            <h4 class="text-center h5 pt-2">
                                {{$t("errorTBAtext")}}
                            </h4>
                        </div>

                        <div class="text-center" style="margin: 3vh auto 0; background-image:url('images/bg-first.png'); background-repeat: no-repeat; background-position: bottom right; min-height:100px;" >
                            <button id="arrow2next" class="btn btn-outline" style="cursor:pointer;" v-if="false">
                                <h2 class="text-default animated2fast bounce2fast">&#8595;</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div> v-else -->
        <HeaderPrice 
          v-if="this.paymentOptionPrice != null && this.paymentOptionPrice?.[paymentFrequencyIGN]?.recurringAmount !== undefined"
          sticky
          :price="formatNumber(policyObject.root.quoteData.transactionType != 'New Biz' && policyObject.root.quoteData.transactionType != 'Renewal' ? this.paymentOptionPrice[paymentFrequencyIGN].additionalEndtInstAmount : this.paymentOptionPrice[paymentFrequencyIGN].recurringAmount )"
          :paymentType="this.defaultPaymentFrequency"
          :rewardNum="formatNumber(Global.motorVehicle.Reward)"
          :isGetReward="Global.motorVehicle.isDefaultReward"
          :opportunityNumber="policyObject.root.quoteData.opportunityNumber"
        />
    <div class="container pt-3">
      <div class="row">
        <div class="alert alert-payment my-3" role="alert">
            <div class="d-flex">
              <img class="img-msg-payment" src="/images/motor-vehicle/WarnAcc.svg" />
              <span class="msg-payment"> {{$t("motorVehicle.messagePayment")}} </span>
            </div>
        </div>
        <div class="col-12">
          <h6 id="choose-method-label" class="text-center fade show mb-3">
            {{ paymentTitle }}
          </h6>
          <h6 class="text-center fade show mb-3 mt-5" v-if="Global.tp === 'rec'">
            {{$t("instalmentNo", {instalmentNo: instalmentNo})}}
          </h6>
          <ul
            class="nav nav-tabs mb-3 mx-md-5"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item col d-flex flex-nowrap" style="padding-left: 0px; padding-right: 0px; flex-basis: 33%;" v-if="!Global.payment.isPmntInitiatedBySF || policyObject.root.quoteData.paymentData.paymentMethod == 'Credit Card' || policyObject.root.quoteData.paymentData.paymentMethod == null">
              <a
                class="nav-link text-center flex-grow-1"
                v-bind:class="{'active' : (!Global.payment.isPmntInitiatedBySF && policyObject.root.quoteData.paymentData.paymentMethod == 'Credit Card') || policyObject.root.quoteData.paymentData.paymentMethod == 'Credit Card' || policyObject.root.quoteData.paymentData.paymentMethod == 'Credit Card'}"
                id="pills-credit-tab"
                data-selenium-name="payment-method"
                data-selenium-value="CC"
                data-toggle="pill"
                role="tab"
                aria-controls="credit-method-method"
                aria-selected="true"
                @click="policyObject.root.quoteData.paymentData.paymentMethod = 'Credit Card';automatic_debit()"
              >
                <!-- <span class="glyphicons glyphicons-credit-card" style="font-size:1rem"></span> -->
                <strong class="text-debit-card" style="font-size:1rem"> {{ $t("cc") }} </strong>
              </a>
            </li>
            <!-- <li class="nav-item col d-flex flex-nowrap" style="padding-left: 0px; padding-right: 0px; flex-basis: 33%;" v-if="!Global.payment.isPmntInitiatedBySF || policyObject.root.quoteData.paymentData.paymentMethod == 'Debit Card' || policyObject.root.quoteData.paymentData.paymentMethod == null">
              <a
                class="nav-link text-center flex-grow-1"
                id="pills-debit-tab"
                v-bind:class="{'active' : (!Global.payment.isPmntInitiatedBySF && policyObject.root.quoteData.paymentData.paymentMethod == 'Debit Card') || policyObject.root.quoteData.paymentData.paymentMethod == 'Debit Card' || policyObject.root.quoteData.paymentData.paymentMethod == 'Debit Card'}"
                data-selenium-name="payment-method"
                data-selenium-value="debit"
                data-toggle="pill"
                role="tab"
                aria-controls="debit-method-method"
                aria-selected="false"
                @click="policyObject.root.quoteData.paymentData.paymentMethod = 'Debit Card';automatic_debit()"
              >
                <strong class="text-debit-card" style="font-size:1rem"> {{ $t("debit_card") }} </strong>
              </a>
            </li> -->
            <li class="nav-item col d-flex flex-nowrap" style="padding-left: 0px; padding-right: 0px; flex-basis: 33%" v-if="!Global.payment.isPmntInitiatedBySF || policyObject.root.quoteData.paymentData.paymentMethod == 'Virtual Account' || policyObject.root.quoteData.paymentData.paymentMethod == null">
              <a
                class="nav-link text-center flex-grow-1"
                v-bind:class="{'active' : (!Global.payment.isPmntInitiatedBySF && policyObject.root.quoteData.paymentData.paymentMethod == 'Virtual Account') || policyObject.root.quoteData.paymentData.paymentMethod == 'Virtual Account' || policyObject.root.quoteData.paymentData.paymentMethod == 'Virtual Account'}"
                id="pills-ebanking-tab"
                data-selenium-name="payment-method"
                data-selenium-value="IB"
                data-toggle="pill"
                role="tab"
                aria-controls="ebanking-method"
                aria-selected="false"
                @click="policyObject.root.quoteData.paymentData.paymentMethod = 'Virtual Account'"
              >
                <!-- <span class="glyphicons glyphicons-global" style="font-size:1rem"></span> -->
                <strong class="text-debit-card" style="font-size:1rem"> Virtual Account </strong>
              </a>
            </li>
          </ul>

          <div
            class="tab-content payment-method-content mx-md-5"
            id="pills-tabContent"
          >
            <div
              class="tab-pane fade show active"
              id="credit-debit-method"
              role="tabpanel"
              aria-labelledby="credit-debit-method-tab"
            >
              <div id="payment-frequency">
                <ul
                  class="nav nav-tabs"
                  id="payment-option-tab"
                  role="tablist"
                >
                  <li
                    class="nav-item text-center col d-flex flex-nowrap"
                  >
                    <a class="nav-link btn-choose flex-grow-1"
                      v-bind:class="{'active' : this.defaultPaymentFrequency === 'Yearly' }"
                      id="full-pay-tab"
                      data-selenium-name="paymentFrequency"
                      data-selenium-value="full"
                      data-toggle="pill"
                      href="#full-pay"
                      role="tab"
                      aria-controls="payment-frequency-tabContent"
                      aria-selected="true"
                      v-on:click="switchPaymentType('Yearly');"
                      >{{ $t("full_mayment") }}</a
                    >
                  </li>
                  <li 
                      class="nav-item text-center col d-flex flex-nowrap" 
                      v-if="isAllowInstallment && (!Global.payment.isPmntInitiatedBySF || (('6 Months' == policyObject.root.quoteData.paymentData.paymentFrequency || policyObject.root.quoteData.paymentData.paymentFrequency == '') && Global.payment.isPmntInitiatedBySF))"
                  >
                    <a
                      class="nav-link btn-choose flex-grow-1 instalment-button"
                      v-bind:class="{'active' : this.defaultPaymentFrequency === '6 Months'}"
                      id="installment-pay-tab"
                      data-selenium-name="paymentFrequency"
                      data-selenium-value="6 months"
                      data-toggle="pill"
                      href="#installment-pay"
                      role="tab"
                      aria-controls="installment-pay"
                      aria-selected="false"
                      v-on:click="switchPaymentType('6 Months'); automatic_debit()"
                      >{{ $t("installment") }}</a
                    >
                  </li>
                </ul>
                <div id="payment-frequency-tabContent" class="tab-content mt-3">
                  <div
                    class="tab-pane fade"
                    v-bind:class="{'show active' : this.defaultPaymentFrequency == 'Yearly'}"
                    id="full-pay"
                    role="tabpanel"
                    aria-labelledby="full-pay-tab"
                  >
                    <div class="card price-card">
                      <div class="card-body p-2 text-center">
                        <h6
                          class="mb-1"
                          data-selenium-name="full-total-price"
                          :data-selenium-value="formatNumber(totalPremium, 0)"
                        >
                          {{ $t("total_price") }}
                        </h6>
                        <h2 class="price-total my-1">
                          <small>{{ $t('motorVehicle.currencySymbol') }} </small> 
                          
                          {{ formatNumber( policyObject.root.quoteData.transactionType != 'New Biz' && policyObject.root.quoteData.transactionType != 'Renewal' ? this.paymentOptionPrice[this.defaultPaymentFrequency].additionalEndtInstAmount : this.paymentOptionPrice[this.defaultPaymentFrequency].recurringAmount ) }}
                          <small>/{{ $t('motorVehicle.year') }}</small>
                        </h2>
                        
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    v-bind:class="{'show active' : this.defaultPaymentFrequency == '6 Months'}"
                    id="installment-pay"
                    role="tabpanel"
                    aria-labelledby="installment-pay-tab"
                  >
                    <div class="card price-card">
                      <div class="card-body p-2 text-center">
                        <!-- <h6
                          data-selenium-name="first-installment"
                          :data-selenium-value="
                            formatNumber(firstInstallmentNoDepositPremium, 0)
                          "
                        >
                          {{ $t("first_installment") }}
                          
                          <span class="first-installment-price">
                            <small>{{ $t('motorVehicle.currencySymbol') }}
                            </small> {{ formatNumber(this.paymentOptionPrice !== null ? this.paymentOptionPrice?.[this.defaultPaymentFrequency]?.recurringAmount : 0) }}
                            /{{ $t('motorVehicle.month') }}
                          </span>
                        </h6> -->
                        <p
                          data-selenium-name="installment-payment"
                          :data-selenium-value="
                            formatNumber(policyObject.root.quoteData.transactionType != 'New Biz' && policyObject.root.quoteData.transactionType != 'Renewal' ? this.paymentOptionPrice[this.defaultPaymentFrequency].additionalEndtInstAmount : this.paymentOptionPrice[this.defaultPaymentFrequency].recurringAmount )
                          "
                        >
                          {{ $t("11_installment") }} <small>{{$t("currency")}}   </small>
                          <strong> {{
                            formatNumber(policyObject.root.quoteData.transactionType != 'New Biz' && policyObject.root.quoteData.transactionType != 'Renewal' ? this.paymentOptionPrice[this.defaultPaymentFrequency].additionalEndtInstAmount : this.paymentOptionPrice[this.defaultPaymentFrequency].recurringAmount )
                          }}
                          </strong>
                          x 6 {{$t("months")}}
                        </p>
                        <h6 class="mb-0" data-selenium-name="total_12_months" :data-selenium-value="formatNumber(this.paymentOptionPrice == null ? 0 : this.paymentOptionPrice?.[this.defaultPaymentFrequency]?.totalPremium)">
                          {{ $t("total_12_months") }}
                          <small>{{$t("currency")}}</small>{{ formatNumber(this.paymentOptionPrice == null ? 0 : this.paymentOptionPrice?.[this.defaultPaymentFrequency]?.totalPremium) }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="policyObject.root.quoteData.paymentData.paymentMethod == 'Credit Card'" id="card-info" class="card mb-4">
                <div class="card-body">
                  <div class="card-logo">
                    <div class="row">
                      <div class="col-7">
                        <h6 class="my-1">{{ $t("card_info") }}</h6>
                      </div>
                      <div class="col-5 text-right">
                        <picture>
                          <source :srcset="MasterCard">
                          <img :src="MasterCard" style="height: 25px;">
                        </picture>

                        <picture>
                          <source :srcset="Visa">
                          <img :src="Visa" style="height: 25px;">
                        </picture>

                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="card-number">{{
                      $t("credit_card")
                    }}</label>
                    <input
                      type="tel"
                      data-selenium-name="card-number"
                      class="form-control card-number"
                      maxlength="19"
                      id="card-number"
                      name="card-number"
                      aria-describedby="card-number"
                      placeholder=".... .... .... ...."
                      v-model.trim="maskCreditCard"
                    />
                  </div>
                  <div class="form-group">
                    <label for="card-name">{{
                      $t("card_holder")
                    }}</label>
                    <input
                      type="text"
                      data-selenium-name="card-name"
                      class="form-control"
                      id="card-name"
                      name="card-name"
                      aria-describedby="card-name"
                      :placeholder="$t('name_on_card')"
                      v-model.trim="cardOwnerName"
                    />
                  </div>
                  <div class="row align-content-stretch">
                    <div class="col d-flex flex-column align-content-stretch">
                      <div class="form-group d-flex flex-column flex-grow-1 align-content-stretch">
                        <label for="card-exp-month" class="flex-grow-1">{{
                          $t("expire_month")
                        }}</label>
                        <select
                          id="card-exp-month"
                          data-selenium-name="card-month"
                          :data-selenium-value="cardExpirationMonth"
                          name="card-exp-month"
                          v-model="cardExpirationMonth"
                          class="form-control custom-select"
                          aria-describedby="card-exp-month"
                        >
                          <option selected value="">{{
                            $t("month")
                          }}</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>
                    <div class="col d-flex flex-column align-content-stretch">
                      <div class="form-group d-flex flex-column flex-grow-1 align-content-stretch">
                        <label for="card-exp-year" class="flex-grow-1">{{
                          $t("expire_year")
                        }}</label>
                        <select
                          id="card-exp-year"
                          data-selenium-name="card-year"
                          :data-selenium-value="cardExpirationYear"
                          name="card-exp-year"
                          v-model="cardExpirationYear"
                          class="form-control custom-select"
                          aria-describedby="card-exp-year"
                        >
                          <option selected value="">{{
                            $t("year")
                          }}</option>
                          <option
                            v-for="year in cardExpirationYears"
                            :key="'card-exp-year' + year"
                            >{{ year }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col d-flex flex-column align-content-stretch">
                      <div class="form-group d-flex flex-column flex-grow-1 align-content-stretch">
                        <label for="card-cvv" class="flex-grow-1">{{ $t("cvv") }} <button
                          id="what-cvv"
                          type="button"
                          class="btn btn-primary"
                          data-toggle="modal"
                          data-target="#cvv-modal"
                        >
                          ?
                        </button></label>
                        
                        <span
                          id="cvv-icon"
                          class="glyphicons glyphicons-credit-card"
                        ></span>
                        <input
                          type="password"
                          data-selenium-name="card-cvv"
                          maxlength="3"
                          class="form-control card-cvv"
                          id="card-cvv"
                          name="card-cvv"
                          aria-describedby="card-cvv"
                          placeholder="..."
                          v-model.trim="securityCode"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="policyObject.root.quoteData.paymentData.paymentMethod == 'Debit Card'">
                <div class="accordion my-3" id="accordion-ebanking">
                  <div class="row" style="margin-left: 0px; margin-right: 0px;">
                    <div class='col-12 col-sm-12 col-md-6 col-lg-6 card' >
                      <div class="card-body text-center" style="padding:0.5rem">
                        <p class="card-title"><strong>Bank BRI</strong> </p>
                      </div>
                      <picture class="text-center" style="padding-bottom:1rem">
                        <source media="(min-width: 520px)" srcset="images/BRI-logo.svg">
                        <img src="images/BRI-logo.svg" class="img-bank" alt="Bank">
                      </picture>
                    </div>
                    <div class='col-12 col-sm-12 col-md-6 col-lg-6 card' >
                      <div class="card-body text-center" style="padding:0.5rem">
                        <p class="card-title"><strong>Bank Mandiri</strong> </p>
                      </div>
                      <picture class="text-center" style="padding-bottom:1rem">
                        <source media="(min-width: 520px)" srcset="images/MANDIRI-logo.svg">
                        <img src="images/MANDIRI-logo.svg" class="img-bank" alt="Bank">
                      </picture>
                    </div>

                  </div>
                </div>
              </div> -->
              <div v-if="policyObject.root.quoteData.paymentData.paymentMethod == 'Virtual Account'">
                <div class="accordion my-3" id="accordion-ebanking">
                  <div class="row" style="margin-left: 0px; margin-right: 0px;">
                    <div :class="'col-'+countVA +' col-sm-'+countVA + ' col-md-'+countVA + ' col-lg-'+countVA + ' card'" v-for="(data,i) in Global.VirtualAccount" :key="i">
                      <div class="card-body text-center" style="padding:0.5rem">
                        <p class="card-title"><strong>Bank {{data.bank}}</strong> </p>
                      </div>
                      <picture class="text-center" style="padding-bottom:1rem">
                        <source media="(min-width: 520px)" :srcset="'images/'+data.bank +'-logo.svg'">
                        <img :src="'images/'+data.bank +'-logo.svg'" class="img-bank" :alt="'Bank'+data.bank">
                      </picture>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="btn-group-toggle text-left label-checkbox my-3"
                data-toggle="buttons"
                v-if="(Global.tp === 'nb' || Global.tp === 'ren')"
              >
                <label 
                  class="btn btn-light btn-check"
                  v-bind:class="{ active: policyObject.root.quoteData.flagAutoRenewal }"
                  for="auto-renewal"
                  data-selenium-name="policyObject.root.quoteData.flagAutoRenewal"
                  :data-selenium-value="policyObject.root.quoteData.flagAutoRenewal ? 'Yes' : 'No'"
                >
                  <input
                    type="checkbox"
                    autocomplete="off"
                    id="auto-renewal"
                    v-model="policyObject.root.quoteData.flagAutoRenewal"
                  />
                  {{ $t("automaticRenewal") }}
                </label>
              </div>

              <!-- <div
                class="btn-group-toggle text-left label-checkbox mb-3"
                data-toggle="buttons"
                v-if="Global.tp === 'nb' && (Global.selectedPackages.Cancer || Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP)"
              >
                <label
                  class="btn btn-light btn-check"
                  v-bind:class="{ active: taxDeduction }"
                  for="tax-deduction"
                  data-selenium-name="taxDeduction"
                  :data-selenium-value="taxDeduction ? 'Yes' : 'No'"
                >
                  <input
                    type="checkbox"
                    autocomplete="off"
                    id="tax-deduction"
                    v-model="taxDeduction"
                  />
                  {{ $t("taxDeductionConsent") }}
                </label>
              </div> -->

              <!-- <div
                class="btn-group-toggle text-left label-checkbox"
                data-toggle="buttons"
                v-if="Global.tp === 'nb'"
              >
                <label
                  class="btn btn-light btn-check"
                  v-bind:class="{ active: ePolicyDocument }"
                  for="e-policy-document"
                  data-selenium-name="ePolicyDocument"
                  :data-selenium-value="ePolicyDocument ? 'Yes' : 'No'"
                >
                  <input
                    type="checkbox"
                    autocomplete="off"
                    id="e-policy-document"
                    v-model="ePolicyDocument" @click="epolicyModal()"
                  />
                  {{ $t("ePolicy") }}
                </label>
              </div> -->

              <!-- <div
                class="btn-group-toggle text-left label-checkbox"
                data-toggle="buttons"
                v-if="Global.tp === 'nb' && (Global.selectedPackages.Cancer || Global.selectedPackages.Covid19 || Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP)"
              >
                <label
                  class="btn btn-light btn-check"
                  v-bind:class="{ active: ePolicyDocument }"
                  for="e-policy-document"
                  data-selenium-name="ePolicyDocument"
                  :data-selenium-value="ePolicyDocument ? 'Yes' : 'No'"
                >
                  <input
                    type="checkbox"
                    autocomplete="off"
                    id="e-policy-document"
                    v-model="ePolicyDocument"
                  />
                  {{ $t("ePolicy") }}
                </label>
              </div> -->
              <div class="my-5" v-show="Global.tp =='nb' || Global.tp === 'ren'">
                <button
                  type="button"
                  class="btn btn-lg btn-block btn-link-default buy-now"
                  data-selenium-name="submitCreditCard"
                  :disabled="policyObject.root.quoteData.paymentData.paymentMethod=='Credit Card' ? !enablePayment : false"
                  id="btn-pay-secure"
                  @click="finalCheckBeforePayment()">
                  {{$t("next")}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

    <div style="padding-top:10vh">&nbsp;</div>
    <Modal
            :show="Global.motorVehicle.isShowInstallmentModal" 
            width="100%" 
            @close="Global.motorVehicle.isShowInstallmentModal = false"
            class="modal-installment-option"
        >
            <div id="installment-detail-modal">
                <div class="modal-header" style="border:0;">
                    <button type="button" data-selenium-name="installment-modal-close-btn" @click="Global.motorVehicle.isShowInstallmentModal = false" class="close"><span aria-hidden="true">x</span></button>
                    <h2 class="text-center h4 mt-5">{{$t("instalmentOptions")}}</h2>
                    <p class="text-center" style="font-size: 16px;">{{$t("instalmentCreditDebitMV")}}</p>
                </div>
                    <div class="panel installment-option mb-5" v-bind:class="{ active: this.defaultPaymentFrequency === 'Yearly'  }">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="Yearly" v-on:click="switchPaymentType('Yearly');Global.motorVehicle.isShowInstallmentModal = false; eventLog('Instalment', 'Yearly');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                <p class="float-right pt-2">{{$t("option1")}}</p>
                        </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("fullPayment")}}</h3>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="fullPayment-totalPrice" :data-selenium-value="formatNumber(this.paymentOptionPrice['Yearly'].totalPremium)">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small>{{formatNumber(this.paymentOptionPrice["Yearly"].totalPremium)}}</strong>
                            </i18n-t>
                        </div>
                    </div>

                    <div class="panel installment-option mb-5"  v-bind:class="{ active: this.defaultPaymentFrequency === '6 Months'  }">
                        <div class="panel-body">
                            <div class="mb-3">
                                <button class="btn-pk-radio" data-dismiss="modal" data-selenium-name="installment-option" data-selenium-value="6 months" v-on:click="switchPaymentType('6 Months');Global.motorVehicle.isShowInstallmentModal = false; eventLog('Instalment', '12 months, no deposit');">
                                    <span class="glyphicons glyphicons-ok-sign"></span>
                                </button>
                                <p class="float-right pt-2">{{$t("option2")}}</p>
                            </div>

                            <div class="text-right">
                                <h3 class="text-head">{{$t("6MonthlyInstalments")}}</h3>
                                <i18n-t tag="p" keypath="payForMonths">
                                  <strong><small>IDR </small>{{formatNumber(this.paymentOptionPrice["6 Months"].firstInstallmentAmount)}}</strong>
                                  <strong>6</strong>
                                </i18n-t>
                            </div>
                        </div>
                        <div class="panel-footer text-right" data-selenium-name="monthly-nodeposit-totalPrice" :data-selenium-value="formatNumber(this.paymentOptionPrice['6 Months'].firstInstallmentAmount)">
                            <i18n-t tag="div" keypath="totalPrice">
                              <strong><small>IDR </small> {{formatNumber(this.paymentOptionPrice["6 Months"].totalPremium)}}</strong>
                            </i18n-t>
                        </div>
                    </div>
                </div>
        </Modal>
    <div class="modal modal-v1 fade" id="automatic-debit-modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              data-selenium-name="epolicy-close"
            >
              X
            </button>
            <h5 class="modal-title mt-4 text-center">
              {{ $t("automatic_debit.title") }}
            </h5>
          </div>
          <div class="modal-body autodebit-body">
            <div class="text-black text-center">
              {{ $t("automatic_debit.body") }}
            </div>
          </div>
          <div class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2">
            <button type="button" class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1" styel="color:#000" id="modal-btn-no" @click="automaticDebitNo()" data-selenium-name="epolicy-no">{{ $t("automatic_debit.button_no") }}</button>
            
            <button type="button" class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1 mb-0" id="modal-btn-yes" @click="automaticDebitYes();" data-selenium-name="epolicy-yes">{{ $t("automatic_debit.button_yes") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="debit-card-modal" tabindex="-1" >
      <div class="modal-dialog" style="max-width:450px !important;">
        <div class="modal-content white-bg" style="padding: 15px 15px !important">
          <div class="modal-body text-center p-5">
            <img src="images/thankyou-va.png" alt="" style="height: 150px" />
            <div class="text-black text-left mt-4">
              <h4>{{ $t("debit_card_modal.title") }}</h4>
              {{ $t("debit_card_modal.body") }} 
              <vue-countdown v-if="counting" :time="5000" @end="onCountdownEnd" v-slot="{ totalSeconds }">{{ totalSeconds }}</vue-countdown> 
              <span v-if="!counting">0</span>
              {{ $t("debit_card_modal.seconds") }}
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center pb-2">
            <button type="button" class="btn btn-cancel mb-0" id="modal-btn-yes" data-selenium-name="debit-payment-cancel" data-dismiss="modal" @click="this.counting = false" style="width:400px;">
              <strong>{{ $t("debit_card_modal.cancel") }}</strong> 
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="debiterror-modal" tabindex="-1">
      <div class="modal-dialog" style="max-width:400px !important;">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
            <div class="text-center mt-3">
              <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sorry4commercial.png" class="lazy" style="width:120px;"/>
            </div>
          </div>
          <div class="modal-body">
            <h6 class="text-black">{{ $t("debit_card_error.title") }}</h6>
            <div class="text-black">
            <span>{{ $t("debit_card_error.message") }}
            <a
                href="tel:02150890821"
                role="button"
                style="color: #ff5011;"
                data-selenium-name="footer-roojai-number"
                data-selenium-value="021 5089 0822"
              >
                Contact Center
              </a>
              {{ $t("debit_card_error.message2") }}</span>
            </div>
          </div>
          <div class="modal-footer pb-2 px-2">
            <button
              type="button"
              class="btn btn-confirm w-100"
              data-dismiss="modal"
              @click="submitDebitCard()"
            >
              {{ $t("debit_card_error.button_confirm") }}
            </button>
            <button
              type="button"
              class="btn btn-cancel w-100 my-3"
              data-dismiss="modal"
            >
              {{ $t("debit_card_error.button_cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    

    <div
      id="payment-accept-modal"
      data-selenium-name="payment-accept-modal"
      class="modal fade insure-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-5 p-md-5">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              data-selenium-name="payment-accept-modal-close"
              aria-label="Close"
              style="color: rgb(244, 67, 54); opacity: 0.8;"
            >
              <span aria-hidden="true">x</span>
            </button>
            <h2 class="h1section-text2 h4 text-left mb-3">
              {{ $t("dear") }}
              {{ Global.phaccount.firstName }} {{ Global.phaccount.lastName }}
            </h2>

            <div
              id="disclaimer"
              class="scrollbar-default"
              data-selenium-name="payment-accept-modal-content"
            >
              <div class="h6 mb-3">
                {{ $t("roojaiContitions") }}
              </div>
              <div class="mb-3">
                {{ $t("pdpaConsent") }}
              </div>

              <div class="h6 pb-3 pt-4" style="text-indent:0px">
                <button
                  type="button"
                  class="btn-nostyle"
                  @click="disclaimerAccepted = !disclaimerAccepted"
                  data-selenium-name="payment-accept-modal-checked"
                  :data-selenium-value="disclaimerAccepted ? 'Yes' : 'No'"
                >
                  <span
                    class="glyphicons"
                    v-bind:class="[
                      disclaimerAccepted
                        ? 'glyphicons-check'
                        : 'glyphicons-unchecked'
                    ]"
                    style="font-size: 24px;color: #FF5011;margin: -9px 5px 0px 0px;"
                  ></span>
                  {{ $t("iHaveRead") }}
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-selenium-name="payment-accept-modal-close-btn"
              class="btn btn-cancel pull-left"
              data-dismiss="modal"
            >
              {{ $t("back") }}
            </button>
            <button v-if="use_va == true"
              type="button"
              data-selenium-name="payment-accept-modal-confirm"
              class="btn btn-confirm pull-right"
              data-dismiss="modal"
              :disabled="!disclaimerAccepted"
              v-on:click="virtualAccount()"
            >
              {{ $t("confirm_pay") }}
            </button>
            <button v-else
              type="button"
              data-selenium-name="payment-accept-modal-confirm"
              class="btn btn-confirm pull-right"
              data-dismiss="modal"
              :disabled="!enablePayment || !disclaimerAccepted"
              v-on:click="confirmCreditCard()"
            >
              {{ $t("confirm_pay") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import axios from "axios";
import accounting from "accounting-js";
import dayjs from "dayjs";
import HeaderPrice from "./components/HeaderPrice.vue";
import Modal from "./components/Modal.vue";
import useClipboard from 'vue-clipboard3'
import MasterCard from '../../../public/images/mastercard.png'
import Visa from '../../../public/images/visa.png'
import {createInputPricingObj, policyServiceMappingObj} from "./data/util.js"
import { policyObject } from "./data/PolicyObject";
import inputPricing from "./data/inputPricingObj.js";
import { 
        formattedDate, 
        getRegisterIGNSdk, 
        setInterceptorObj,
        oneYearLater, 
        ymdFormatedDate,
        dayMonthYearFormatedDate
} from "./lib/index.js";


import {
    updatePaymentFrequency,
    saveQuote,
    setPolicyServiceInputObj,
    setInputPricing,
    getAvailablePaymentFrequency,
    setClientRegInfo,
    getRewardPoint,
    calculateSelectedPlanPrice,
    calculatePlanOptionsPrice,
    getPolicyPaymentOptionPrice,
    getDefaultPaymentFrequency,
    calculateSelectedPlanPriceWithInstallments,
    // setPolicyServiceMappingFromInputPriceResponse,
    getCoveragePrice,
    calculatePlanPriceForInstallOptions,
    getPaymentDetailsData,
    getPolicyServiceInputObj
} from "./lib/IGNLibrary/IGNPricingEngine.js";

export default {
  name: "payment",
  mixins: [Mixin],
  components: {
    HeaderPrice,
    Modal
  },
  data() {
    return {
      Global,
      policyObject,
      inputPricing,
      reward:0,
      isDefaultReward: false,
      MasterCard : MasterCard,
      Visa : Visa,
      maskCreditCard: "",
      cardOwnerName: "",
      cardNumber: "",
      cardExpirationMonth: "",
      cardExpirationYear: "",
      securityCode: "",
      hdCardBank: "",
      hdCardOtherBank: "",
      cardExpirationYears: [],
      taxDeduction: false,
      autoRenewFlag: false,
      disclaimerAccepted: false,
      // ePolicyDocument: true,
      instalmentNo: 0,
      vaNumber: null,
      bankCode: null,
      disabledToggle: false,
      use_va: false,
      showPriceHeader: false,
      counting: false,
      listPayment: ['Month', 'Yearly'],
      paymentType: {
          name: "Month",
          value: true
      },
      availablePaymentFrequency: [],
      coverType: null,
      regData: null,
      defaultPaymentFrequency: null,
      coveragePrice: {},
      paymentOptionPrice : null,
      va_prefix_bca: process.env.VUE_APP_VA_PREFIX_BCA,
      va_prefix_bni: process.env.VUE_APP_VA_PREFIX_BNI,
      va_prefix_mandiri: process.env.VUE_APP_VA_PREFIX_MANDIRI,
      paymentMethod: (policyObject.root.quoteData.paymentData.paymentMethod == 'Credit Card' ? 'card' : 'Debit Card' ? 'debit' : 'Virtual Account' ? 'va' : '')
    };
  },
  async created() {
      this.loading("show");
      window.addEventListener('scroll', this.onScrollNavbar);
      window.scrollTo(0, 0);

      try{
        await this.checkPayment(this.policyObject.root.quoteData.opportunityNumber);
      }catch(e){
        this.loading("hide");
        console.log(e);
        // this.errorModal(this.$t("packageUnavailable"),"mv error");
        this.errorModal(this.$t("trxNotFound"), 'title', this.$t("trxNotFoundTitle"), this.$t("trxNotFoundContact")); 
      }
      if(this.isAlreadyPaid == true && this.policyObject.root.quoteData.transactionType == 'New Biz'){
        this.loading("hide");
        // this.errorModal(this.$t("packageUnavailable"),"mv error");
        this.errorModal(this.$t("alreadyPaid"), 'title', this.$t("alreadyPaidTitle"), this.$t("alreadyPaidContact"), this.$t("alreadyPaid2")); 
      }else{
        var clientRegInfo = getRegisterIGNSdk();
        let inputPricingObj = createInputPricingObj();
        await setClientRegInfo(clientRegInfo);
        await setInputPricing(inputPricingObj);
        if (Global.motorVehicle.flagRetrievePolicy) {
          await setPolicyServiceInputObj(this.policyObject);
        }
        var isEndtOrCancelQuote = (this.policyObject.root.quoteData.transactionType!=null && 
              this.policyObject.root.quoteData.transactionType !== "New Biz" && 
              this.policyObject.root.quoteData.transactionType !== "Renewal");
        if(isEndtOrCancelQuote){
          await calculateSelectedPlanPriceWithInstallments();
        }else{
          await calculateSelectedPlanPrice();
        }
        if (this.policyObject.root.quoteData.transactionType == "Renewal") {
          let quotation = {
            "checkPayment": true,
            "opportunityNumber": this.policyObject.root.quoteData.opportunityNumber
          }
          var response = await axios.post(
            this.urlDecommission.urlPaymentVA,
            quotation,
            {
              headers: { Authorization: "Bearer " + this.urlDecommission.token },
            }
          );
          this.loading("hide", function () {
            if (response.status != 200) {
              this.loading("hide", function () {
                this.errorModal(this.$t("vaError"));
              });
            }
          });
        }
        if(this.policyObject.root.quoteData.paymentData.paymentMethod == null && !Global.payment.isPmntInitiatedBySF){
          this.policyObject.root.quoteData.paymentData.paymentMethod = 'Credit Card'
        }
        this.paymentOptionPrice = getPolicyPaymentOptionPrice();
        this.defaultPaymentFrequency = getDefaultPaymentFrequency();
        this.getRewardPoint(); 
        this.loading("hide");
      }
  },
  mounted() {
    window.scrollTo(0, 0);
    $(window).off("scroll");
    $("nav").removeClass("fixed-menu");
    $("body").removeClass("scroll-down");

    var now = dayjs(Global.serverTime, "YYYY-MM-DD HH:mm:ss");
    for (var year = now.year(); year < now.year() + 10; year++) {
      this.cardExpirationYears.push(year.toString());
    }
    if(this.defaultPaymentFrequency == '6 Months' && policyObject.root.quoteData.paymentData.paymentMethod == 'Credit Card'){
        $("#automatic-debit-modal").modal("show");
        Global.motorVehicle.quote.Online_Screen = "/mv/payment";
        this.policyObject.root.quoteData.onlineScreen = "/mv/payment";
    }
    this.policyObject.root.quoteData.onlineStage = "Payment";
    this.getVirtualAccount();
  },
  methods: {
    finalCheckBeforePayment(){
      let vm = this;
      let paymentMethod = vm.policyObject.root.quoteData.paymentData.paymentMethod
      if(paymentMethod=='Credit Card') vm.submitCreditCard()
      else if(paymentMethod=='Virtual Account') vm.submitVirtualAccount()
      else {
        vm.errorModal(vm.$t("paymentMethodError"));
      }
    },
    async getData() {
            
      this.loading("show");
      try {
          let inputPricingObj = createInputPricingObj();
          setInputPricing(inputPricingObj);
          await calculatePlanOptionsPrice(true);
          await this.bindData();               
          await this.saveQuoteFetch();
          this.loading("hide");
      } catch (error) {
          this.loading("hide");
          this.errorModal(this.$t("error.connection.message"));
          console.log(error)
      }
    },
    async postalCodeDetails(postal) {
        try {
            const response = await this.request().get(Global.restServer.url + "/postals?search=province&keyword=" + postal);
            if (response.data?.model?.found == true) {
                Global.motorVehicle.postalCodeDetails = response.data.model;
                return response
            } else {
                Global.isValidated.postcode = false;
                this.showNotValid = true;
                return response
            }
        } catch (error) {
            console.error("getProvince => ", error);
        }
    },
    async actionLogRequest(req){
      let response
      try {
        response = await axios.post(Global.roojaiService.url + "/logger/sf", req,
        {
          headers: { Authorization: "Bearer " + Global.roojaiService.token }
        });
      } catch (error) {
        console.error(error);
      }
      return response
    },
    async bindData() {
      this.availablePaymentFrequency = getAvailablePaymentFrequency();
      this.defaultPaymentFrequency = getDefaultPaymentFrequency();
      this.coveragePrice = getCoveragePrice();
      this.paymentOptionPrice = getPolicyPaymentOptionPrice();
      this.getRewardPoint();  
    },

    updatePriceToGlobal(value, gross) {
      this.paymentOptionPrice[this.defaultPaymentFrequency].recurringAmount = value;
      this.paymentOptionPrice[this.defaultPaymentFrequency].totalPremium = gross;
    },

    onScrollNavbar() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition > 240) {
          this.showPriceHeader = true;
      } else {
          this.showPriceHeader = false;
      } 

      if (currentScrollPosition < 3000) {
          this.showFloating = true;
      } else {
          this.showFloating = false;
      }
    },

    getRewardPoint(){
      let rewardRes = getRewardPoint();
      if (rewardRes > 0 && Global.motorVehicle.isDefaultReward == null) {
          Global.motorVehicle.isDefaultReward = true;
          Global.motorVehicle.DefaultReward = rewardRes;
          this.isDefaultReward = true
          Global.motorVehicle.DefaultReward = rewardRes;
      }
      Global.motorVehicle.Reward = rewardRes
    },

    async switchPaymentType(value) {
      updatePaymentFrequency(value, false);
      this.bindData();          
      this.defaultPaymentFrequency = value;
    },

    async checkPayment(oppty){
      let checkPaymentResp = await axios.post(Global.roojaiService.url + "/payments/lasttransaction",//await axios.post(Global.restServer.url + "/payments/creditCardPaymentByXendit",
      {
        opportunityNumber: oppty
      },
      {
        headers: {
          Authorization: "Bearer " + Global.roojaiService.token,
        },
      });
      this.isAlreadyPaid = checkPaymentResp.data.success;

    },

    updateDefaultPaymentFrequency(value) {
        updatePaymentFrequency(value);
    },
    automatic_debit:function(){
      if(!Global.payment.isPmntInitiatedBySF && (this.defaultPaymentFrequency == '6 Months' && (policyObject.root.quoteData.paymentData.paymentMethod == 'Credit Card' || policyObject.root.quoteData.paymentData.paymentMethod == 'Debit Card'))){
        $("#automatic-debit-modal").modal("show");
      }
    },
    automaticDebitYes: function() {
      this.defaultPaymentFrequency = '6 Months';
      this.switchPaymentType(this.defaultPaymentFrequency);
      $("#automatic-debit-modal").modal("hide");
    },
    automaticDebitNo: function() {
      this.defaultPaymentFrequency = 'Yearly' ;
      this.switchPaymentType(this.defaultPaymentFrequency);
      $("#automatic-debit-modal").modal("hide");
    },
    checkAvailableInstallmentVA: function() {
      this.defaultPaymentFrequency = 'Yearly' ;
      this.switchPaymentType(this.defaultPaymentFrequency);
    },
    submitCreditCard: function () {
      this.confirmCreditCard();
    },
    confirmCreditCard: function () {
      var vm = this;

      vm.loading("show");
      var merchantTotalAmount = "0";
      merchantTotalAmount = parseFloat(policyObject.root.quoteData.transactionType != 'New Biz' && policyObject.root.quoteData.transactionType != 'Renewal' ? vm.paymentOptionPrice[vm.defaultPaymentFrequency].additionalEndtInstAmount : vm.paymentOptionPrice[vm.defaultPaymentFrequency].recurringAmount);
      Xendit.setPublishableKey(Global.xenditPublicKey);
      Xendit.card.createToken({
        amount: parseInt(merchantTotalAmount.toString().replace(".","")),
        card_number: vm.cardNumber,
        card_exp_month: vm.cardExpirationMonth,
        card_exp_year: vm.cardExpirationYear,
        card_cvn: vm.securityCode,
        currency: "",
        is_multiple_use: true,
        should_authenticate: true
    }, async function (err, creditCardToken){
      if (err) {
        vm.loading("hide", function () {
          vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
        });
        console.log(err)
        return;
      }

      if (creditCardToken.status === 'VERIFIED') {
        // Get the token ID:
        var token = creditCardToken.id;
        var authenticationId = creditCardToken.authentication_id;
        var paymentResponse = null;


        try {
          paymentResponse = await axios.post(Global.roojaiService.url + "/payments/creditCard",//await axios.post(Global.restServer.url + "/payments/creditCardPaymentByXendit",
                {
                  amount: merchantTotalAmount,
                  tokenId: token,
                  authenticationId: authenticationId,
                  opportunityNumber: vm.policyObject.root.quoteData.opportunityNumber,
                  issuingBank: creditCardToken.card_info.bank,
                  paymentFrequency: vm.defaultPaymentFrequency,
                  customerName: vm.cardOwnerName,
                  autoRenewFlag: vm.autoRenewFlag === true ? "Yes" : "No",
                  isPmntInitiatedBySF: vm.policyObject.root.quoteData.paymentData.isPmntInitiatedBySF,
                  agentId: vm.policyObject.root.quoteData.paymentData.isPmntInitiatedBySF,
                  card: {
                    number: creditCardToken.masked_card_number,
                    brand: creditCardToken.card_info.brand,
                    expMonth: vm.cardExpirationMonth,
                    expYear: vm.cardExpirationYear
                    }
                },
                {
                  headers: {
                    Authorization: "Bearer " + Global.roojaiService.token,
                  },
                });
                Global.paymentID = paymentResponse.data.paymentID;

              if (paymentResponse.data.paymentStatus === "00") {
                // mapping
                Global.motorVehicle.payment.paymentDetailsData = [
                  {
                    dueDate: ymdFormatedDate(paymentResponse.data.paymentDate),
                    chargeId: paymentResponse.data.chargeID,
                    paymentDate: ymdFormatedDate(paymentResponse.data.paymentDate),
                    bank : "Xendit",
                    type :"Receivable",
                    installmentNumber: "1",
                    amount: parseFloat(policyObject.root.quoteData.transactionType != 'New Biz' && policyObject.root.quoteData.transactionType != 'Renewal' ? vm.paymentOptionPrice[vm.defaultPaymentFrequency].additionalEndtInstAmount : vm.paymentOptionPrice[vm.defaultPaymentFrequency].recurringAmount),
                    status: "Paid",
                  }
                ];
                Global.motorVehicle.payment.creditCard = {
                  cardId: token,
                  // customerId: authenticationId,
                  customerId: null,
                  cardNumber: creditCardToken.masked_card_number,
                  expiryMonth: vm.cardExpirationMonth,
                  expiryYear: vm.cardExpirationYear,
                  nameOnCard: vm.cardOwnerName,
                  issuingBank: creditCardToken.card_info.bank,
                  cardType: creditCardToken.card_info.brand
                }
                vm.policyObject.root.quoteData.paymentData.paymentDetailsData = Global.motorVehicle.payment.paymentDetailsData
                vm.policyObject.root.quoteData.paymentData.creditCard = Global.motorVehicle.payment.creditCard
                vm.policyObject.root.quoteData.paymentData.paymentFrequency = vm.defaultPaymentFrequency
                vm.policyObject.root.quoteData.paymentData.paymentMethod = "Credit Card"
                // vm.policyObject.root.quoteData.startDate = ymdFormatedDate(new Date());
                // vm.policyObject.root.quoteData.endDate = oneYearLater(new Date());
                vm.policyObject.root.quoteData.paymentData.paidAmount = parseFloat(policyObject.root.quoteData.transactionType != 'New Biz' && policyObject.root.quoteData.transactionType != 'Renewal' ? vm.paymentOptionPrice[vm.defaultPaymentFrequency].additionalEndtInstAmount : vm.paymentOptionPrice[vm.defaultPaymentFrequency].recurringAmount);
                vm.policyObject.root.quoteData.paymentData.flagPaymentConfirmed = true;
                vm.policyObject.root.quoteData.paymentData.flagDeferredPayment = false;
                if (vm.policyObject.root.quoteData.transactionType == "Renewal") {
                  vm.policyObject.root.quoteData.isActivatePolicy = true;
                }
                Global.paymentCompleted = true;
                vm.saveQuoteFetch('credit card');
                vm.loading("hide");
                if (policyObject.root.quoteData.transactionType == 'Endorsement' || policyObject.root.quoteData.transactionType == 'Renewal') {
                  vm.$router.push({name: 'mv-thankyou'});
                }else{
                  vm.$router.push({name: 'mv-policydetails2'});
                }
              }else if(paymentResponse.data.paymentStatus === "99"){
                vm.loading("hide");
                vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
                Global.paymentCompleted = false;
              } else {
                Global.paymentCompleted = false;
                Global.quote.onlineStage = "failure";
                vm.loading("hide");
                vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
              }
        } catch (error) {
          console.error(error);
          Global.paymentID = error.response?.data?.paymentID;
          Global.paymentCompleted = false;
          Global.quote.onlineStage = "failure";
          vm.policyObject.root.quoteData.onlineStage = "failure";
          vm.loading("hide", function () {
            vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
          });
        }
        
      } else if (creditCardToken.status === 'IN_REVIEW') {
        vm.loading("hide", function () {
          window.open(creditCardToken.payer_authentication_url, 'sample-inline-frame');
        });
      } else {
        vm.loading("hide", function () {
          vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
        });
      }
    })
    },
    async saveQuoteFetch(value){
      this.policyObject.root.quoteData.onlineScreen = "/mv/payment";
      this.policyObject.root.quoteData.onlineStage = "Payment";
      
      let dobPolicyHolder = null;
      let dobInsuredData= null;
      this.policyObject.root.quoteData.policyHolderData.dob = dayMonthYearFormatedDate(this.policyObject.root.quoteData.policyHolderData.dob,"day");
      dobPolicyHolder = ymdFormatedDate(String(this.policyObject.root.quoteData.policyHolderData.dob).split("-").reverse().join("-"))           
      dobInsuredData = ymdFormatedDate(String(this.policyObject.root.quoteData.insuredData.dob))           
      this.policyObject.root.quoteData.policyHolderData.dob = dobPolicyHolder; 
      if(this.policyObject.root.quoteData.vehicleData.isMainDriverPH)           
          this.policyObject.root.quoteData.insuredData.dob = dobInsuredData;
      this.policyObject.root.quoteData.paymentData.paymentFrequency = this.defaultPaymentFrequency;
      
      setPolicyServiceInputObj(this.policyObject);
      // setPolicyServiceMappingFromInputPriceResponse(policyServiceMappingObj);
      try {
        let interceptorObj = setInterceptorObj();
        let resQuote = await saveQuote(interceptorObj);
        if (value == 'credit card') {
          let policyService = getPolicyServiceInputObj()
          const requestQuote = policyService;
          let req = { requestQuote ,
            'responseSf': { resQuote }
          }
          this.actionLogRequest(req)
        }
        return resQuote
      } catch (error) {
        console.log(error);
        this.errorModal(this.$t("error.connection.message"));
        return error
      }     
    },
    creditcardFormat: function (cc, delimeter) {
      if (!cc) {
        return cc;
      }

      delimeter = delimeter || " ";
      const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
      const onlyNumbers = cc ? cc.replace(/[^\d]/g, "") : "xxxx";

      return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
        [$1, $2, $3, $4].filter((group) => !!group).join(delimeter)
      );
    },
    checkAddressAndBeneficiary: function () {
      return (
        Global.phaccount.postalCode !== "" &&
        Global.phaccount.province !== "" &&
        Global.phaccount.provinceCode !== "" &&
        Global.phaccount.district !== "" &&
        Global.phaccount.districtCode !== "" &&
        Global.phaccount.subDistrict !== "" &&
        Global.phaccount.subDistrictCode !== "" &&
        Global.phaccount.houseNumber !== ""
      );
    },
    getEndorsementData: function () {
      return axios.get(
        Global.sfmcsService.url +
          "/endorsement/" +
          encodeURIComponent(Global.encryptedOpportunityNumber),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Global.sfmcsService.token,
          },
        }
      );
    },
    makeAPayment: async function (tokenId) {
      var object = {
          source: {
            amount: Math.round(Global.premiumToPay * 100),
            currency: "THB",
          },
          charge: {
            metadata: {
              reference1: "01",
              refNo: Global.serverTime.replace(/\D/g, ""),
              isPmntInitiatedBySF: Global.payment.isPmntInitiatedBySF,
              agentId: Global.payment.agentId,
              tp: Global.tp,
            },
            description: Global.quote.opportunityNumber,
            capture: true,
          },
          token: tokenId,
        },
        charge = await axios.post(
          Global.roojaiService.url + "/payments/charge",
          object,
          {
            headers: { Authorization: "Bearer " + Global.roojaiService.token },
          }
        );

        if(charge.data.status === "successful" && Global.tp === "rec" && ["Personal Accident", "Health"].indexOf(Global.quote.productType) > -1){
          this.loading("hide");
          this.$router.replace("review");
          consolelog("PAYMENT SUCCESS")
        }
    },
    getProtectionQuotation: function(){
      Global.quote.taxDeduction = this.taxDeduction === true ? "Yes" : "No";
      var quotation = this.getQuotation();
      delete quotation["productType"];

      quotation.item.quote.isActivatePolicy = false;
      quotation.item.payment = {
        isPmntInitiatedBySF: Global.payment.isPmntInitiatedBySF,
        agentId: Global.payment.agentId,
        deferredPaymentFlag: false
      };

      quotation.item.payments = [
        {
          Payment_Fee__c: "0",
          Payment_date__c: "",
          Bank__c: "Xendit",
          Type__c: "Receivable",
          WHT_certificate_number__c: "",
          Installment_number__c: "1",
          Credit_Amount__c: this.amount,
          Payment_frequency__c: this.defaultPaymentFrequency,
          Status__c: "",
          Payment_method__c: "",
          Charge_Id__c: ""
        }
      ];
      return quotation;
    },
    submitDebitCard: function () {
      this.debitCard();
    },
    debitCard: async function() {
      let vm = this;
      vm.loading("show");
        try {
          if (Global.motorVehicle.flagRetrievePolicy) {
            await vm.saveQuoteFetch()
          }
          Global.motorVehicle.payment.paymentDetailsData = [
            {
              dueDate: "",
              chargeId: "",
              paymentDate: "",
              bank : "Ayoconnect",
              type :"Receivable",
              installmentNumber: "1",
              amount: parseFloat(vm.paymentOptionPrice[vm.defaultPaymentFrequency].recurringAmount),
              status: "",
            }
          ];
          vm.policyObject.root.quoteData.paymentData.paymentDetailsData = Global.motorVehicle.payment.paymentDetailsData
          vm.policyObject.root.quoteData.paymentData.paymentFrequency = vm.defaultPaymentFrequency
          vm.policyObject.root.quoteData.paymentData.paymentMethod = "Debit Card"
          vm.policyObject.root.quoteData.paymentData.paidAmount = parseFloat(vm.paymentOptionPrice[vm.defaultPaymentFrequency].recurringAmount);
          vm.policyObject.root.quoteData.paymentData.flagPaymentConfirmed = true;
          vm.policyObject.root.quoteData.paymentData.flagDeferredPayment = false;
          var response = await axios.post(
              Global.odataService.url + "/payment/ayc/createBinding",
              {
                "root": vm.policyObject,
                "phoneNumber": (vm.policyObject.root.quoteData.policyHolderData.phoneNumber == null ? Global.motorVehicle.mainDrivers.phoneNumber : vm.policyObject.root.quoteData.policyHolderData.phoneNumber),
                "transactionType": "07"
              },
              {
                headers: { Authorization: "Bearer " + Global.odataService.token },
              }
            );
          vm.vaNumber = null;
          Global.quote.taxDeduction = vm.taxDeduction === true ? "Yes" : "No";
          Global.paymentID = response.data.paymentID;

          vm.loading("hide", function () {
            if (response.data.success === true) {
              Global.quote.onlineStage = "Proceed to payment";
              vm.policyObject.root.quoteData.onlineStage = "Proceed to payment";
              Global.debitcardInfo.response = response.data.redirect_url
              vm.loading("hide");
              vm.counting = true;
              $("#debit-card-modal").modal("show");
            }else {
              vm.loading("hide", function () {
                vm.errorModal(vm.$t("debitCardError"),"debit card");
              });
            }
          });
        } catch (error) {
          console.error(error);
          vm.loading("hide", function () {
            vm.errorModal(vm.$t("debitCardError"),"debit card");
          });
        }
    },
    submitVirtualAccount: function () {
      this.virtualAccount();
    },
    virtualAccount: async function() {
      let vm = this;
      vm.loading("show");
      if(vm.defaultPaymentFrequency == 'Yearly') {
        await calculateSelectedPlanPrice()
      } else {
        await calculateSelectedPlanPriceWithInstallments()
      }
      let paymentDetailsData = await getPaymentDetailsData()
      let yearlyData =  [{
        amount: parseFloat(vm.paymentOptionPrice[vm.defaultPaymentFrequency].recurringAmount), 
        dueDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        installmentNumber: 1,
        paymentDate: null,
        status: 'Pending',
        type: 'Receivable'
      }]
      vm.policyObject.root.quoteData.paymentData.paymentDetailsData = vm.defaultPaymentFrequency == 'Yearly' ? yearlyData : paymentDetailsData
      vm.policyObject.root.quoteData.paymentData.paymentFrequency = vm.defaultPaymentFrequency
      vm.policyObject.root.quoteData.paymentData.paymentMethod = "Virtual Account"
      vm.policyObject.root.quoteData.paymentData.paidAmount = parseFloat(vm.paymentOptionPrice[vm.defaultPaymentFrequency].recurringAmount);
      vm.policyObject.root.quoteData.paymentData.flagPaymentConfirmed = false;
      vm.policyObject.root.quoteData.paymentData.flagDeferredPayment = true;
      
      if (vm.policyObject.root.quoteData.transactionType == "Renewal") {
        vm.policyObject.root.quoteData.isActivatePolicy = true;
      }else if (Global.isAgentCustomerView) {
        vm.policyObject.root.quoteData.isActivatePolicy = true;
        vm.policyObject.root.quoteData.vehicleData.flagInspectionRequired = "Yes"
        vm.policyObject.root.quoteData.vehicleData.inspectionLimit = "8"
        vm.policyObject.root.quoteData.vehicleData.inspectionMethod = "VDO Inspection"
      }
      vm.policyObject.root.quoteData.onlineScreen = "/mv/payment";
      vm.policyObject.root.quoteData.onlineStage = "Payment";
      let dob = null;
      vm.policyObject.root.quoteData.policyHolderData.dob = dayMonthYearFormatedDate(vm.policyObject.root.quoteData.policyHolderData.dob,"day");
      dob = ymdFormatedDate(String(vm.policyObject.root.quoteData.policyHolderData.dob).split("-").reverse().join("-"))           
      vm.policyObject.root.quoteData.policyHolderData.dob = dob; 
      if(vm.policyObject.root.quoteData.vehicleData.isMainDriverPH)           
          vm.policyObject.root.quoteData.insuredData.dob = dob;
      vm.policyObject.root.quoteData.paymentData.paymentFrequency = vm.defaultPaymentFrequency;
      
      await setPolicyServiceInputObj(vm.policyObject);
      try {
        var response = await axios.post(
          Global.roojaiService.url + "/policy/service", { "root": vm.policyObject.root }, {headers: { Authorization: "Bearer " + Global.roojaiService.token },}
        );
        vm.loading("hide");
        if (response?.data?.success == true) {
          Global.quote.onlineStage = "Proceed to payment";
          vm.policyObject.root.quoteData.onlineStage = "Proceed to payment";
          Global.VAinfo.accountNumber = response.data.vaDetails[0].accountNumber
          Global.VAinfo.totalPayment = response.data.vaDetails[0].price
          Global.VAinfo.frequency = ''
          Global.VAinfo.bank = response.data.vaDetails[0].bank
          Global.responseCreateVa = response.data.vaDetails
          let mappingResultVA = []
          response.data.vaDetails.forEach((element) => 
            mappingResultVA.push({[element.bank]:[element]})
          );
          Global.resultCreateVa = mappingResultVA;
          vm.loading("hide");
          vm.$router.push({ name: 'mv-waitingpayment' });
        } else {
          vm.loading("hide", function () {
            vm.errorModal(vm.$t("vaError"));
          });
        }
      }  catch (error) {
        console.error(error);
        vm.loading("hide", function () {
          vm.errorModal(vm.$t("vaError"));
        });
      }
    },
    onCountdownEnd: function () {
      this.counting = false;
      $("#debit-card-modal").modal("hide");
      if (Global.debitcardInfo.response != null) {
        this.redirect();
        // this.$router.push({name: 'debitcard'});
      }
      
    },
    redirect() {
      window.location.assign(Global.debitcardInfo.response);
    },
    showmodaldebitcard: function () {
      $("#debit-card-modal").modal("show");
    },
    getProtectionQuotationDebit: function(){
      Global.quote.taxDeduction = this.taxDeduction === true ? "Yes" : "No";
      var quotation = this.getQuotation();
      delete quotation["productType"];

      quotation.item.quote.isActivatePolicy = false;
      quotation.item.payment = {
        isPmntInitiatedBySF: Global.payment.isPmntInitiatedBySF,
        agentId: Global.payment.agentId,
        deferredPaymentFlag: false
      };

      quotation.item.payments = [
        {
          Payment_Fee__c: "0",
          Payment_date__c: "",
          Bank__c: "Ayoconnect",
          Type__c: "Receivable",
          WHT_certificate_number__c: "",
          Installment_number__c: "1",
          Credit_Amount__c: this.amount,
          Payment_frequency__c: this.defaultPaymentFrequency,
          Status__c: "",
          Payment_method__c: "",
          Charge_Id__c: ""
        }
      ];
      return quotation;
    }
  },
  watch: {
    maskCreditCard: function (value) {
      value = value.replace(/[^0-9]/g, "");
      this.maskCreditCard = this.creditcardFormat(value);

      if (value.length == 16) {
        this.cardNumber = value.replace(/\s+/g, "");
      }
    },
    hdCardBank: function (value) {
      if (value != "other") {
        this.hdCardOtherBank = "";
      }
    },
  },
  computed: {
    enablePayment: function () {
      return (
        this.cardNumber.length == 16 &&
        this.cardOwnerName &&
        this.securityCode.length == 3 
        // &&
        // (this.hdCardBank ||
        //   (this.hdCardBank == "other" && this.hdCardOtherBank))
      );
    },
    paymentFrequencyIGN(){
      return this.defaultPaymentFrequency
    },
    countVA: function () {
      return (
        Global.VirtualAccount.length==1?12:Global.VirtualAccount.length==2?6:Global.VirtualAccount.length>=3?4:4
      );
    },
    paymentTitle: function () {
      return Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor
        ?.value > 0
        ? this.$t("choose_payment_method")
        : this.$t("payment");
    },
  },
};
</script>

<i18n>
{
  "en": {
    "errorTBAtitle" : "Oops, Something went wrong",
    "errorTBAtext": "It looks like our server is playing up right now. Try again in a bit.",
    "payment": "Your payment",
    "choose_payment_method": "Choose your payment method",
    "cc": "Credit Card",
    "debit_card": "Debit Card",
    "full_mayment": "Full payment",
    "installment": "Instalment",
    "total_price": "Total Price",
    "select_bank": "Select a bank",
    "continue": "Continue",
    "your_vanumber": "Virtual Account Number",
    "copy_clipboard": "Copy",
    "first_installment": "Initial deposit",
    "11_installment": "Instalment",
    "11_months": "x 11 months",
    "total_12_months": "Total price",
    "card_info": "Card informations",
    "credit_card": "Credit / Debit card number",
    "card_holder": "Card holder name",
    "name_on_card": "Name on card",
    "expire_month": "Expire month",
    "expire_year": "Expire year",
    "cvv": "CVV",
    "month": "Month",
    "year": "Year",
    "currency" :"IDR ",
    "issuing_bank": "Card issuing bank",
    "bank_name": "BANK NAME",
    "bank_other": "Other bank",
    "confirm_pay": "Confirm and Pay",
    "creditCardErrorTitle":"Oops, The Payment Didn't Go Through",
    "creditCardError":"Please make sure your credit card is active and has sufficient balance. You can also try with a different credit card or a different method of payment. If you need assistance, please ",
    "creditCardErrorContact":"Contact Us",
    "vaError":"Oops, an error occurred, and we cannot proceed virtual account payment. Please try again later",
    "debitCardError":"Oops, an error occurred, and we cannot proceed debit card payment. Please try again later",
    "paymentMethodError":"Please choose a payment method",
    "months": "Months",
    "total": "Total",
    "taxDeductionConsent": "To exercise the right to apply for income tax deduction under the tax legislation, I consent to the insurer to disclose the insurance premium data to the Revenue Department following the prescribed conditions and methods.",
    "automaticRenewal": "Automatically renew my policy",
    "productCancer": "Cancer",
    "ePolicyTitle": "E-policy",
    "ePolicy": "Yes, I want to save the environment by receiving my e-policy",
    "ePolicyDesc": "Are you sure you want to receive a hardcopy? A surcharge might apply for the hardcopy. E-policy is much faster, safer, easy to access anytime anywhere and environmentally friendly.",
    "ePolicyYes": "Yes, I want e-policy and save the environment!",
    "ePolicyNo": "No, I need the hardcopy",
    "dear": "Hi",
    "roojaiContitions": "You are one step closer to having your protection! Please confirm your acceptance of the following points to continue.",
    "pdpaConsent": "I agree that the insurer can collect, use and disclose your personal information or those whom you represent to buy this product, to insurance agents, insurance brokers, other relevant third party, such as third-party service providers of the insurance company, supervisory bodies such as the Indonesian Financial Services Authority (Otoritas Jasa Keuangan/OJK), and other relevant government institution. This shall include the health information of the relevant persons of this policy. The data can be used for underwriting and claims purposes or any other insurance arrangements and regulations of the industry.",
    "iHaveRead": "I have read and agree to the above statement",
    "instalmentNo": "Instalment no. {instalmentNo}",
    "instalmentCreditDebitMV": "Available for Credit Card, and Debit Card",
    "automatic_debit" : {
      "title" : "Automatic Debit",
      "body" : "I agree for automatic debiting of my credit/debit card listed above.",
      "button_no" : "I disagree",
      "button_yes" : "I agree"
    },
    "debit_card_modal" : {
      "title" : "Hang Tight!",
      "body" : "You’re being redirected to another page. It may take up to ",
      "seconds" : " seconds",
      "cancel" : "Cancel Payment"
    },
    "debit_card_error" : {
      "title" : "Seems like we’re facing an issue",
      "message" : "We couldn’t get you to your destination page. Try again in a few minutes or contact our ",
      "message2" : "",
      "button_cancel" : "Change Payment Method",
      "button_confirm" : "Try Again",
    }
  },
  "id": {
    "errorTBAtitle" : "Maaf, Terjadi Kesalahan",
    "errorTBAtext": "Sepertinya server kami sedang terkendala sekarang. Coba lagi beberapa saat.",
    "payment": "Pembayaran kamu",
    "choose_payment_method": "Pilih metode pembayaran",
    "cc": "Kartu Kredit",
    "debit_card": "Kartu Debit",
    "full_mayment": "Pembayaran penuh",
    "installment": "Cicilan",
    "total_price": "Harga total",
    "select_bank": "Pilih bank",
    "continue": "Lanjut",
    "your_vanumber": "Nomor Virtual Account",
    "copy_clipboard": "Salin",
    "first_installment": "Cicilan awal",
    "11_installment": "Cicilan",
    "11_months": "x 11 bulan",
    "total_12_months": "Harga total",
    "card_info": "Informasi kartu",
    "credit_card": "Nomor kartu kredit/debit",
    "card_holder": "Nama pemegang kartu",
    "name_on_card": "Nama pada kartu",
    "expire_month": "Bulan kadaluwarsa",
    "expire_year": "Tahun kadaluwarsa",
    "cvv": "CVV",
    "month": "Bulan",
    "year": "Tahun",
    "currency" :"Rp",
    "issuing_bank": "Bank penerbit kartu",
    "bank_name": "NAMA BANK",
    "bank_other": "Bank lain",
    "confirm_pay": "Konfirmasi dan bayar",
    "automatic_debit" : {
      "title" : "Pendebetan Otomatis",
      "body" : "Saya setuju untuk pendebetan otomatis kartu kredit/debit yang tertera di atas.",
      "button_no" : "Saya tidak setuju",
      "button_yes" : "Saya setuju"
    },
    "debit_card_modal" : {
      "title" : "Mohon Tunggu",
      "body" : "Kamu akan diarahkan ke halaman baru dalam waktu  ",
      "seconds" : " detik",
      "cancel" : "Batalkan Pembayaran"
    },
    "debit_card_error" : {
      "title" : "Halaman yang kamu tuju bermasalah",
      "message" : "Kami tidak dapat menghubungkanmu ke halaman yang dituju. Silakan coba lagi dalam beberapa menit atau hubungi ",
      "message2" : "kami.",
      "button_cancel" : "Ubah Metode Pembayaran",
      "button_confirm" : "Coba Lagi",
    },
    "creditCardErrorTitle":"Ups, Pembayaranmu Belum Berhasil",
    "creditCardError":"Pastikan kartu kredit kamu masih aktif dan limit kartu masih cukup. Kamu juga bisa mencoba pembayaran dengan kartu kredit lain atau gunakan metode pembayaran lainnya. Jika kamu butuh bantuan silakan ",
    "creditCardErrorContact":"Hubungi Kami",
    "vaError":"Ups, terjadi kesalahan dan kami tidak dapat memproses pembayaran virtual account. Silakan coba lagi nanti",
    "debitCardError":"Ups, terjadi kesalahan dan kami tidak dapat memproses pembayaran debit card. Silakan coba lagi nanti",
    "paymentMethodError":"Mohon pilih metode pembayaran",
    "months": "Bulan",
    "total": "Total",
    "taxDeductionConsent": "Untuk menggunakan hak untuk mengajukan pengurangan pajak penghasilan berdasarkan undang-undang pajak, saya menyetujui perusahaan asuransi untuk mengungkapkan data premi asuransi kepada Departemen Pendapatan dengan mengikuti kondisi dan metode yang ditentukan.",
    "automaticRenewal": "Perpanjang polis secara otomatis",
    "productCancer": "Kanker",
    "ePolicyTitle": "E-polis",
    "ePolicy": "Saya mau menyelamatkan bumi dengan menerima polis digital (e-policy)",
    "ePolicyDesc": "Yakin mau menerima polis cetak? Kamu bisa dikenakan biaya jika memilih polis cetak. Dengan memilih polis digital, kamu bisa akses polis kapan saja, dimana saja selama 24/7.",
    "ePolicyYes": "Ya, saya mau e-polis dan selamatkan bumi!",
    "ePolicyNo": "Tidak, saya perlu polis cetak",
    "dear": "Hai",
    "roojaiContitions": "Kamu sudah satu langkah lebih dekat untuk mendapatkan proteksi dari Roojai! Silahkan konfirmasi pernyataan di bawah, dan kami akan memproses asuransi kamu.",
    "pdpaConsent": "Saya setuju bahwa perusahaan asuransi dapat mengumpulkan, menggunakan, dan mengungkapkan informasi pribadi kamu atau yang kamu wakili untuk membeli produk ini, kepada agen asuransi, pialang asuransi, pihak ketiga lain yang relevan, seperti penyedia jasa pihak ketiga dari perusahaan asuransi, badan pengawas seperti Otoritas Jasa Keuangan (OJK), dan lembaga pemerintah lainnya yang terkait. Hal ini mencakup rekam medis dari orang-orang yang relevan dari kebijakan ini. Data tersebut dapat digunakan untuk tujuan penjaminan dan klaim atau pengaturan dan peraturan asuransi lainnya.",
    "iHaveRead": "Saya sudah membaca dan menyetujui pernyataan diatas",
    "instalmentNo": "Nomor cicilan {instalmentNo}",
    "instalmentCreditDebitMV": "Tersedia untuk Kartu Kredit, dan Debit"
  },
}
</i18n>
